.aboutHome-senegal {
  height: auto;
}
.aboutHome-senegal #heading {
  text-align: left;
  padding: 0;
}
.aboutHome-senegal .left-senegal img {
  width: 85%;
  height: 100%;
  object-fit: cover;
  margin-left: 50px;
}
.aboutHome-senegal .right-senegal {
  padding: 80px 50px;
}
.aboutHome-senegal .items-senegal {
  margin-top: 50px;
}
.aboutHome-senegal .item-senegal {
  background-color: #fff;
  margin-top: 30px;
  padding: 20px;
  transition: 0.5s;
}
.aboutHome-senegal .img-senegal {
  width: 200px;

}
.aboutHome-senegal img {
  width: 70px;
  height: 70px;
  border-radius: 5px;

}
.aboutHome-senegal .item-senegal h2 {
  font-size: 20px;
  margin-bottom: 15px;
}
.aboutHome-senegal .item-senegal p {
  color: #999999;
}
.aboutHome-senegal .item-senegal:hover {
  background: linear-gradient(
    to right,
    #6f483f,
    var(--primary-color-safety-orange)
  );
  color: #fff;
  cursor: pointer;
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
}
.aboutHome-senegal .item-senegal:hover p {
  color: #fff;
}


@media screen and (max-width: 768px) {
  .aboutHome-senegal .container-senegal {
    flex-direction: column-reverse;
  }
  .aboutHome-senegal .row-senegal {
    width: 100%;
  }

}
