.cookie-consent {
    position: fixed;
    bottom: 20px;
    left: 20px;
    overflow: hidden;
    justify-self: start;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.895);
    /* background-color: var(--secondary-color-pakistan-green); */
    /* background-color: var(--primary-color-harvest-gold); */
    /* background-color: var(--primary-color-safety-orange); */
    background-color: var(--primary-color-selective-yellow);
    /* background-color: #005238; */
    backdrop-filter: blur(5px);
    padding: 16px 32px;
    text-align: center;
    margin: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 6;
    /* border: #6ccddc solid 1px; */
  }
  
  .cookie-header {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
  }
  .cookie-header-title {
    display: flex;
    align-items: baseline;
    gap: 16px;
  }
  .cookie-icon {
    height: 3rem;
  }
  .cookie-mentions-legales {
    text-decoration: none;
  }
  .cookie-mentions-legales-text {
    /* color: black; */
    color : var(--secondary-color-pakistan-green);
    text-decoration: underline;
    text-underline-offset: 0.2rem;
  }
  
  .cookie-consent p {
    margin-bottom: 2%;
  }
  
  .cookie-footer {
    display: flex;
  }
  
  .cookie-primary-button {
    background: linear-gradient(
      to left,
      var(--secondary-color-pakistan-green),
      var(--secondary-color-pakistan-green)
    );



    color: #fff;
    border: none;
    padding: 8px 16px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1em;
  }
  
  .cookie-primary-button:hover {
    background: #005238;
  }
  
  .cookie-secondary-button {
    /* background: linear-gradient(
      to left,
      rgba(53, 175, 194, 0.5),
      rgba(42, 118, 180, 0.5)
    ); */
    background: linear-gradient(
      to left,
      var(--secondary-color-bard-red),
      var(--secondary-color-bard-red)
    );
    color:white;
    border: none;
    padding: 8px 16px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1em;
  }
  
  .cookie-secondary-button:hover {
    background:  #9e503f;
  }
  
  @media only screen and (max-width: 600px) {
    .cookie-consent {
      width: 70%;
      left: 0;
      bottom: 0;
      padding: 8px 48px;
      font-size: 14px;
    }
    .cookie-consent button {
      margin-bottom: 5%;
    }
  }
  