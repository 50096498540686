.tunisia-testimonials-wrapper{
    width: 100%;
    /* padding:6rem 0 12rem 0; */
    padding: 8rem 0;
    display:flex;
    flex-direction:column;
    align-items: center;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.tunisia-testimonials-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:2rem;
}

.tunisia-testimonials-header{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap:1.75rem;
    font-size: 1.25rem;
    line-height: 1.75;
}

.tunisia-testimonials-title{
    font-size: 1.875rem;
    font-weight: 700;
    letter-spacing: -0.05em;
    line-height: 2.25rem;
    text-align: center;
}

@media (min-width:550px){
    .tunisia-testimonials-title{
        font-size: 2.5rem;
    }
}

.tunisia-testimonials-subtitle{
    max-width: min(70%,900px);
    font-size: 1rem;
    line-height: 1.75rem;
    text-align: center;
}

@media (min-width:768px){
    .tunisia-testimonials-subtitle{
        font-size: 1.25rem;
    }
}

@media (min-width:1024px){
    .tunisia-testimonials-subtitle{
        font-size: 1rem;
    }
    .tunisia-testimonials-title{
        font-size: 2.25rem;
    }
}

@media (min-width:1280px){
    .tunisia-testimonials-subtitle{
        font-size: 1.25rem;
    }
}

@media (min-width: 1300px){
    .tunisia-testimonials-title{
        font-size: 2.75rem;
        line-height: 120%;
    }
}

@media screen and (min-width:1920px){
    .tunisia-testimonials-title{
        font-size: 3.75rem;
    }
}


.tunisia-testimonials-header-badge{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 0.35rem 1.25rem;
    background-color: #00213b;
    color: white;
    font-weight: 600;
    background: linear-gradient(
    to right,
    #6f483f,
    var(--primary-color-safety-orange)
    );
}



.tunisia-testimonials-body{
    width:85%;
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:2rem;
}


@media (min-width:960px){
    .tunisia-testimonials-body{
        flex-direction: row;
        justify-content: center;
        gap:48px;
        max-width: 1400px;
    }
}

/* ANIMATIONS */
#testimonial-card-2-1{
    opacity: 0;
    transform: translateX(-100px);
    transition: all 0.5s ease-in-out;
}

#testimonial-card-2-1.visible{
    opacity: 1;
    transform: translateX(0px);
    transition: all 0.5s ease-in-out;
}

#testimonial-card-2-2{
    opacity: 0;
    transform: scale(0.6);
    transition: all 0.5s ease-in-out;
}

#testimonial-card-2-2.visible{
    opacity: 1;
    transform: scale(1);
    transition: all 0.5s ease-in-out;
}

#testimonial-card-2-3{
    opacity: 0;
    transform: translateX(100px);
    transition: all 0.5s ease-in-out;
}

#testimonial-card-2-3.visible{
    opacity: 1;
    transform: translateX(0px);
    transition: all 0.5s ease-in-out;
}

@media (max-width:960px){
    #testimonial-card-2-2{
        opacity: 0;
        transform: translateX(100px);
        transition: all 0.5s ease-in-out;
    }
    
    #testimonial-card-2-2.visible{
        opacity: 1;
        transform: translateX(0px);
        transition: all 0.5s ease-in-out;
    }

    #testimonial-card-2-3{
        opacity: 0;
        transform: translateX(-100px);
        transition: all 0.5s ease-in-out;
    }
    
    #testimonial-card-2-3.visible{
        opacity: 1;
        transform: translateX(0px);
        transition: all 0.5s ease-in-out;
    }
}