/* Style de base pour la page */

.validation {
  isolation: isolate;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: 100px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 10rem;
  position: relative;
  background-color: transparent;
  width: 70%;
  max-width: 1400px;
}
.validation-container {
  width: 100%;
  padding: 2rem;
}
.validation-container .validation-wrapper .validation-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 100%;
  height: 100%;
}

/* Style pour les titres de section */
.validation-wrapper > * h2 {
  font-size: 30px;
  color: #00213b;
  font-weight: bold;
  font-family: "Raleway", sans-serif;
  font-size: 1.7rem;
  margin-bottom: 2rem;
  white-space: nowrap;
}

.validation-wrapper .panier {
  grid-column: span 2;
  border-radius: 10px;
  background-color: rgba(244, 244, 244, 0.77);
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05), 0 -4px 8px rgba(0, 0, 0, 0.05),
    4px 0 8px rgba(0, 0, 0, 0.05), -4px 0 8px rgba(0, 0, 0, 0.05);
}
.validation-wrapper .totale {
  grid-column: span 1;
  padding: 2rem;
  background-color: rgba(244, 244, 244, 0.77);

  display: flex;
  flex-direction: column;

  border-radius: 10px;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05), 0 -4px 8px rgba(0, 0, 0, 0.05),
    4px 0 8px rgba(0, 0, 0, 0.05), -4px 0 8px rgba(0, 0, 0, 0.05);
}

.totale {
  display: flex;
  justify-content: space-between;
}

.validation-wrapper .totale > div h3 {
  color: #00213b;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  font-size: 1.3rem;
}
.panier {
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin-top: 2rem;
    border-radius: 10px;

    .input-layout {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
    .input-container {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
      position: relative;
      .label {
        color: #00213b;
        font-weight: 500;
        font-family: "Raleway", sans-serif;
        font-size: 1.1rem;
        width: fit-content;
        position: relative;
        display: block;
        padding-top: 5px;
      }
      input {
        height: 45px;
        border: 1px solid hsl(0, 0%, 80%);
        /* border: 1px solid #00213B; */
        border-radius: 6px;
        padding: 0.5rem;
      }
      input::placeholder {
        color: #bebebe;
        font-size: 14px;
      }
      .PhoneInput {
        height: 45px;
        border: 1px solid hsl(0, 0%, 80%);
        /* border: 1px solid #00213B; */
        border-radius: 6px;
        padding-left: 10px;
        .PhoneInputCountry {
          position: relative;
          align-self: stretch;
          display: flex;
          align-items: center;
          margin-right: var(--PhoneInputCountrySelect-marginRight);
        }
        .PhoneInputInput {
          border-left: none;
          border-radius: 0;
          border-right: none;
          outline: none;
          flex: 1 1;
          min-width: 0;
          height: 45px;
        }
      }
    }
  }
}

.flex-colum {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  .btn {
    font-weight: 700;
    color: white;
    background: linear-gradient(
      to right,
      #db9b8c,
      var(--primary-color-safety-orange)
    );
    padding: 0.5rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
  }
}
.css-19bb58m {
  margin-bottom: -0.2rem !important;
}
.css-19bb58m {
  margin-bottom: -1rem;
}
@media (max-width: 768px) {
  .validation {
    width: 100%;
    margin-bottom: 3rem;
    padding-top: 2rem;
  }
}
@media (max-width: 1200px) {
  .validation {
    width: 100%;
    margin-bottom: 0;
    padding-top: 2rem;
  }
}
@media (max-width: 768px) {
  .validation-container .validation-wrapper .validation-grid {
    grid-template-columns: 1fr;
    gap: 3rem;
    .totale {
      grid-column: span 2;
    }
  }
}
@media (max-width: 768px) {
  .panier form .input-layout {
    grid-template-columns: 1fr;
  }
}
@media (min-width: 320px) and (max-width: 1024px) {
  .validation-wrapper > * h2 {
    white-space: wrap;
  }
  .panier .form .input-layout {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Style du récapitulatif de commande */
.order-summary {
  grid-column: span 1;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  .divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.17);
    margin: 1rem 0;
  }
}

.order-summary h2 {
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
  color: #22303f;
}

.order-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.order-item:last-child {
  border-bottom: none;
}

.order-item p {
  margin: 0;
  color: #555;
}

.total-summary {
  margin-top: 20px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
  .divider {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.17);
    margin: 1rem 0;
  }
}

.total h3 {
  color: #00213b;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  font-size: 1.3rem;
}

.total-amount,
.sub-total-amount,
.tva-amount {
  font-weight: bold;
  color: #333;
}

.total-summary .total-amount {
  font-size: 1.5rem;
  color: #007bff;
}

/* Style pour les détails de facturation */
.billing-details {
  grid-column: span 2;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05), 0 -4px 8px rgba(0, 0, 0, 0.05),
    4px 0 8px rgba(0, 0, 0, 0.05), -4px 0 8px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background-color: rgba(244, 244, 244, 0.77);
  padding: 2rem;
}
.billing-details h2 {
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
  color: #22303f;
}

.billing-from {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-top: 2rem;
  border-radius: 10px;
}

.billing-from label {
  font-weight: bold;
  color: #333;
}

.billing-from input {
  height: 45px;
  border: 1px solid hsl(0, 0%, 80%);
  /* border: 1px solid #00213B; */
  border-radius: 6px;
  padding: 0.5rem;
  width: 100%;
}

.billing-from input:focus {
  border: 1px solid #007bff;
  outline: none;
}

/* Champ qui prend toute la ligne */
.billing-from .full-width {
  grid-column: span 2;
}

/* Style de la section paiement */
.payment-section {
  margin-top: 20px;
}

.paypal-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.paypal-button button {
  padding: 12px 24px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s;
}

.paypal-button button:hover {
  background-color: #0056b3;
}

/* Style du lien retour */
.link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: #007bff;
  font-weight: 600;
  margin-top: 3rem;
  font-size: 1.2rem;
}

/* Structure totale pour le résumé et total */
.total {
  display: flex;
  justify-content: space-between;
}
