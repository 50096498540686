.strie-container {
    height: 28px;
    width: 80px;
    border-radius: 40px;
    position: absolute;
    background-size: 100% 100%;
    animation: strie-apparition 5s ease-in-out, gradient 5s ease-in-out infinite;
  }
  
  .strie-container.red {
    background: linear-gradient(
      to right,
      var(--primary-color-harvest-gold),
      var(--primary-color-safety-orange)
    );
  }

  .strie-container.light-harvest-gold {
    background: linear-gradient(
      to right,
      var(--primary-color-harvest-gold),
      #f2a156
    );
  }

  .strie-container.harvest-gold-yellow{
    background: linear-gradient(
      to right,
      var(--primary-color-harvest-gold),
      var(--primary-color-selective-yellow)
    );
  }
  
  .strie-container.blue {
    background: linear-gradient(
      to right,
      var(--secondary-color-bard-red),
      var(--primary-color-safety-orange)
    );
  }

  .strie-container.green-yellow {
    background : linear-gradient(
      to right,
     
      #ffeea9, #005238);
  }

  .strie-container.green-green {
    background : linear-gradient(
      to right,
      #1C4013, #317D1F);

      background : linear-gradient(
        to right,
        #1C4013, #317D1F);

      /* background: linear-gradient(to right, #1C4013, #3da126); */
      background: linear-gradient(to right,#3da126, #1C4013 );
  }

  .strie-container.redBlue {
    background: linear-gradient(
      to right, 
      var(--secondary-color-bard-red),
      #0b76c5
    );
  }

  .strie-container.orange-africa {
    background: linear-gradient(90deg,#f27601,#ffb602);
  }

  .strie-container.bard-brown{
    background: linear-gradient(
      to right,
      var(--secondary-color-bard-red),
      var(--primary-color-safety-orange)
    );
  }

  .strie-container.brown-beige{
    background: linear-gradient(
      to right,
      #6f483f,
      var(--primary-color-safety-orange)
    );
  }

  .strie-container.brown{
    /* background: linear-gradient(
      to right,
      #4b1408,
      var(--primary-color-safety-orange)
    ); */
    background: linear-gradient(to right, #6f3021, var(--primary-color-safety-orange));
  }

  

  
  @keyframes strie-apparition {
    from {
      scale: 0.5;
    }
  }
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  /* @media (max-width: 768px) {
    .strie-container {
      width: 55px;
      height: 11px;
    }
  } */

  @media (max-width: 768px) {
    .strie-container {
      width: 65px;
      height: 15px;
    }
  }
  
  /* @media (max-width: 920px) and (min-width: 768px) {
    .strie-container {
      width: 100px;
      height: 15px;
    }
  } */

  @media (max-width: 920px) and (min-width: 768px) {
    .strie-container {
      width: 70px;
      height: 18px;
    }
  }

  @media (max-width: 1024px) and (min-width: 920px) {
    .strie-container {
      width: 80px;
      height: 15px;
    }
  }

  @media (max-width: 1200px) and (min-width: 1024px) {
    .strie-container {
      width: 100px;
      height: 25px;
    }
  }
  
  @media (max-width: 1550px) and (min-width: 1200px) {
    .strie-container {
      width: 100px;
      height: 25px;
    }
  }

  @media (min-width: 1550px) {
    .strie-container {
      width: 100px;
      height: 25px;
    }
  }
  