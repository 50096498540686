/***********************************/
/***************DESIGN SYSTEM**********/

@import url("https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400..700;1,400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Athiti:wght@200;300;400;500;600;700&family=Expletus+Sans:ital,wght@0,400..700;1,400..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Athiti:wght@200;300;400;500;600;700&family=Expletus+Sans:ital,wght@0,400..700;1,400..700&family=Hind+Madurai:wght@300;400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Proza+Libre:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Proza+Libre:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");

@import url("css/fade-in-from-left-below.css");
@import url("css/fade-slide-in.css");
@import url("css/colors.css");

/* Harvest Gold : #DD9D3A */
/* Safety Orange : #F27601 */
/* Selective Yellow : #FFB602 */

/* Secondary colors */
/* Bard Red : #6F1A07 */
/* Pakistan Green : #1C4013 */

:root {
  --primary-color-harvest-gold: #dd9d3a;
  --primary-color-safety-orange: #f27601;
  --primary-color-selective-yellow: #ffb602;
  --secondary-color-bard-red: #6f1a07;
  --secondary-color-pakistan-green: #1c4013;
}


/******************************/
/*********FONT STYLE**********/
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li,
button,
label,
input,
textarea,
span {
  margin: 0; /* A COMPREND PK !!!!*/
  font-weight: 400;
}

h1 {
  /* font-family: "Expletus Sans"; */
  font-family: "Titillium Web";
}
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Athiti"; */
  font-family: "Proza Libre";
}
body {
  color: #00213b;
  overflow: auto;
  /* font-family: "Hind Madurai"; */
  font-family: "Noto Sans";
}
/* div {
  color: #2c2e35;
} */

.bold {
  font-weight: 700;
}
.semi-bold {
  font-weight: 600;
}
.regular {
  font-weight: 400;
}

h1 {
  font-size: 47.8px;
  letter-spacing: -2px;
}

h2 {
  font-size: 39.8px;
  letter-spacing: -1px;
}

h3 {
  font-size: 33.2px;
  letter-spacing: -1px;
}

h4 {
  font-size: 27.6px;
}

h5 {
  font-size: 23px;
}

h6 {
  font-size: 20.2px;
  text-align: center;
}

p,
button {
  font-family: "Hind Madurai";
  font-size: 16px;
}

/* Media queries pour les petits écrans */
@media (max-width: 768px) {
  h1 {
    font-size: 32.44px;
  }

  h2 {
    font-size: 28.83px;
  }

  h3 {
    font-size: 25.63px;
  }

  h4 {
    font-size: 22.78px;
  }

  h5 {
    font-size: 20.25px;
  }

  h6 {
    font-size: 18px;
  }

  p {
    font-size: 0.875em;
    line-height: 1.4;
  }

  /* Ajoutez d'autres éléments et leurs styles ici */
}

/***********************************/

html {
  scroll-behavior: smooth;
}
