.fade-in-left-below {
  opacity: 0;
  transform: translateY(50px) translateX(-50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}


.fade-in-left-below.visible {
  opacity: 1;
  transform: translateX(0) translateY(0);
}
