/* .formation-mali-body {
    background-color: #14161b;
} */

.formation-mali-body {
    position: relative; /* Ajoutez cette ligne */
}

.slick-slide > div {
    margin: 0 10px;
}

.slick-dots li button:before {
    color: black !important; /* Default dot color */
}

.slick-dots li.slick-active button:before {
    color: red !important; /* Active dot color */
}

.mali-container {
    width: 75%;
    margin: auto;
    position: relative; /* Ajoutez cette ligne */
}

.formation-header-mali {
    text-align: center;
    margin-bottom: 20px;
}

.formation-header-mali h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #14161b; /* Adjust the color as needed */
}

.mali-carousel-container {
    margin-top: 20px;
}

.mali-card {
    background-color: rgb(245, 243, 243);
    height: 450px;
    color: black;
    border-radius: 16px;
}

.mali-card-image-container {
    height: 224px;
    background-color: #092835; /* Equivalent of bg-indigo-500 */
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.mali-card-image {
    height: 176px;
    width: 176px;
    border-radius: 50%;
}

.mali-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 16px;
}

.mali-card-title {
    font-size: 1.25rem;
    font-weight: 600;
}

.mali-card-review {
    text-align: center;
}

.mali-card-button {
    background-color: #8778dd; /* Change the button color to black */
    color: white;
    font-size: 1.125rem;
    padding: 8px 24px;
    border-radius: 16px;
    cursor: pointer; /* Change cursor to pointer */
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effect */
}

.mali-card-button:hover {
    background-color: #175874; /* Darker background on hover */
    transform: scale(1.05); /* Slightly enlarge the button on hover */
}

/* Add these styles to change the arrow color */
.slick-prev:before, .slick-next:before {
    color: black !important;
}

.slick-prev:hover:before, .slick-next:hover:before {
    color: rgb(31, 29, 29) !important; /* Change hover color if needed */
}
