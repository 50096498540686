/*************************************************/

.kids-section-wrapper {
  isolation: isolate;
  position: relative;
  background-color: transparent;
  min-width: min-content;
  width: 90%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 6vh 0; */
  padding: 6vh 0 12vh 0;
  gap: 2vh;
}

.kids-section-container {
  /* width: 70%; */
  width: 85%;
  min-width: min-content;
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  gap: 2vw;
  /* border: greenyellow solid; */
  cursor: alias;
}

.kids-section-description {
  min-width: 300px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2vh;
  /* border:red solid */
}

.kids-section-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;
  transition: 2s;
}
.kids-section-header:hover {
  transform: scale(1);
  color: #dd5c29;
  h2 {
    font-size: 50px;
  }
}

.kids-section-footer {
  display: flex;
  gap: 16px;
}

.kids-section-button {
  display: flex;
  text-decoration: none;
  width: fit-content;
  position: relative;
  color: #236aae;
  /* display:flex; */
}
.kids-section-button:hover {
  color: #25aabe;
}

.kids-section-button:before {
  content: "";
  border-bottom: 1px solid #25aabe;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  transition: 0.3s;
}

.kids-section-button:hover:before {
  width: 100%;
  padding: 0;
}

.kids-section-illustration {
  flex: 1;
  min-width: 300px;
  /* max-height: 400px; */
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 200px minmax(150px, 300px);
  /* grid-template-rows: 260px minmax(260px, 300px); */
  grid-template-rows: 50% 50%;
  width: 50%;
  aspect-ratio: 1/1;
  row-gap: 1vh;
  column-gap: 1vh;
  /* border: blue solid; */
}

.kids-section-image {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 15px;
  /* border: purple solid; */
  box-shadow: 0px 5px 10px 0px #e9e9e9;
  transform: scale(1);
  opacity: 1;
}
.kids-section-image:hover {
  transition: 200ms;
  transform: scale(1.03);
  box-shadow: 0px 5px 10px 0px rgb(216 148 67 / 54%);
}

.main-kids-image {
  grid-column: 1/3;
  /* grid-row:1/3 */
}

/* ANIMATIONS */

.bounce-in-right {
  animation: bounce-in-right 2s ease;
}
@keyframes bounce-in-right {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(-30px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.bounce-in-left {
  animation: bounce-in-left 2s ease;
}
@keyframes bounce-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1000px);
  }
  60% {
    opacity: 1;
    transform: translateX(30px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.bounce-in-right.visible {
  animation: bounce-in-right 2s ease forwards;
}

@keyframes kids-section-slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.kids-section-illustration {
  opacity: 0;
}

.kids-section-illustration.visible {
  opacity: 1;
  /* animation: bounce-in-right 3s ease forwards; */
  animation: kids-section-slide-in-left 2s cubic-bezier(0.25, 0.1, 0.25, 1)
    forwards;
}

@keyframes kids-section-description-slide-in-right {
  0% {
    opacity: 0;
    transform: translateX(50px) translateY(35px);
  }
  100% {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
}

.kids-section-description {
  opacity: 0;
}

.kids-section-description.visible {
  opacity: 1;
  animation: kids-section-description-slide-in-right 3s
    cubic-bezier(0.25, 0.1, 0.25, 1) forwards;

  ul li:hover {
    color: #ff6600; /* Couleur au survol */
    transform: scale(1.1); /* Agrandissement au survol */
  }
}

/* RESPONSIVE */

@media (max-width: 768px) {
  .kids-section-footer {
    flex-direction: column;
  }
}
