.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  /*max-width: 500px;
  width: 100%;*/
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.modal-overlay.show .modal-content {
  opacity: 1;
  transform: translateY(0);
  width: 70%;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
.modal-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.training-card-2-modal-contact,
.training-card-2-modal-download,
.training-card-2-modal-close {
  margin-top: 20px;
}

.training-card-2-modal-download button {
  padding: 15px 25px;
  background: linear-gradient(
    to right,
    #6f483f,
    var(--primary-color-safety-orange)
  );

  color: white;
  border: none;
  cursor: pointer;
  border-radius: 15px;
}

.training-card-2-modal-download button:hover {
  background-color: #0056b3;
}
/* ScrollableDiv.css */
.scrollable-container {
  width: 100%;
  height: 80vh;
  /*height: 300px;
  border: 1px solid #ccc;*/
  overflow-y: auto; /* Barre de défilement verticale si nécessaire */
  overflow-x: hidden; /* Masquer la barre de défilement horizontale */
  padding: 10px;
  box-sizing: border-box;
  h2 {
    text-align: center;
    margin-bottom: 15px;
  }
}

.scrollable-content {
  p {
    margin: 10px 0;
  }
  img {
    max-width: none;
  }
}
