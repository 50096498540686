
@media (max-width:550px){
    #tunisia-trainings-top-left-strie-1 {
        top: 0% !important;
        left:-5% !important;
        z-index:-1 !important;
        transform: scale(4.5) rotate(-50deg) !important;
    }
    #tunisia-trainings-top-left-strie-2 {
        /* top: 0; */
        display: none;
    }

    #tunisia-trainings-bottom-right-strie-1{
        display: none;
    }

    #tunisia-trainings-bottom-right-strie-2{
        top: 99% !important;
        left:103% !important;
        z-index: -1 !important;
    }
}

@media (min-width:550px){
    #tunisia-trainings-top-left-strie-1 {
        top: 1% !important;
        left:-5% !important;
        z-index:0 !important;
        transform: scale(5) rotate(-50deg) !important;
        z-index: 1 !important;
    }
    #tunisia-trainings-top-left-strie-2 {
        display: block;
        top:3% !important;
        left:-5% !important;
        z-index: 0 !important;
    }

    #tunisia-trainings-bottom-right-strie-1{
        display: block;
        left: 95% !important;
        top: 98% !important;
    }

    #tunisia-trainings-bottom-right-strie-2{
        top: 100% !important;
        left: 96% !important;
        transform: scale(6) rotate(-50deg) !important;
    }
}


@media (min-width: 768px){
    #tunisia-trainings-top-left-strie-1 {
        top: 2% !important;
        left: 0% !important;
    }
    #tunisia-trainings-top-left-strie-2 {
        top: 10% !important;
        left: -2% !important;
    }

    #tunisia-trainings-bottom-right-strie-1{
        top: 102% !important;
        z-index: -1 !important;
    }

    #tunisia-trainings-bottom-right-strie-2{
        top: 106% !important;
        z-index: -1 !important;
    }
}

@media (min-width: 920px){
    #tunisia-trainings-top-left-strie-1 {
        top: 8% !important;
        left: -5% !important;
        transform: scale(7.5) rotate(-50deg) !important;
    }
    #tunisia-trainings-top-left-strie-2 {
        top: 11% !important;
        left: -2% !important;
        z-index: 0 !important;
    }

    #tunisia-trainings-bottom-right-strie-1{
        top: 102% !important;
        z-index: -1 !important;
    }

    #tunisia-trainings-bottom-right-strie-2{
        top: 104% !important;
        z-index: -1 !important;
        left: 100% !important;
        transform: scale(7.5) rotate(-50deg) !important;
    }
}

@media (min-width: 1024px){
    #tunisia-trainings-top-left-strie-1 {
        top: 7% !important;
        left: -1% !important;
        transform: scale(5.5) rotate(-50deg) !important;
    }
    #tunisia-trainings-top-left-strie-2 {
        top: 16% !important;
        left: -2% !important;
        z-index: 0 !important;
        transform: scale(2) rotate(-50deg) !important;
    }

    #tunisia-trainings-bottom-right-strie-1{
        top: 93% !important;
        left: 93% !important;
        z-index: -1 !important;
        transform: scale(2) rotate(-50deg) !important;
    }

    #tunisia-trainings-bottom-right-strie-2{
        top: 100% !important;
        left: 95% !important;
        z-index: -1 !important;
        transform: scale(5.5) rotate(-50deg) !important;
    }
}

@media (min-width: 1280px){
    #tunisia-trainings-top-left-strie-1 {
        top: 7% !important;
        left: -1% !important;
        transform: scale(6) rotate(-50deg) !important;
    }
    #tunisia-trainings-top-left-strie-2 {
        top: 18% !important;
        left: -2% !important;
        z-index: 0 !important;
        transform: scale(2.5) rotate(-50deg) !important;
    }

    #tunisia-trainings-bottom-right-strie-1{
        top: 90% !important;
        left: 93% !important;
        z-index: -1 !important;
        transform: scale(2.5) rotate(-50deg) !important;
    }

    #tunisia-trainings-bottom-right-strie-2{
        top: 98% !important;
        left: 95% !important;
        z-index: -1 !important;
        transform: scale(6) rotate(-50deg) !important;
    }
}


@media (min-width: 1600px){
    #tunisia-trainings-top-left-strie-1 {
        top: 10% !important;
        left: -1% !important;
        transform: scale(6) rotate(-50deg) !important;
    }
    #tunisia-trainings-top-left-strie-2 {
        top: 25% !important;
        left: -2% !important;
        z-index: 0 !important;
        transform: scale(2.5) rotate(-50deg) !important;
    }

    #tunisia-trainings-bottom-right-strie-1{
        top: 85% !important;
        left: 96% !important;
        z-index: -1 !important;
        transform: scale(2.5) rotate(-50deg) !important;
    }

    #tunisia-trainings-bottom-right-strie-2{
        top: 98% !important;
        left: 96% !important;
        z-index: -1 !important;
        transform: scale(6) rotate(-50deg) !important;
    }
}


@media (min-width: 1740px){
    #tunisia-trainings-top-left-strie-1 {
        top: 15% !important;
        left: -1% !important;
        transform: scale(7) rotate(-50deg) !important;
    }
    #tunisia-trainings-top-left-strie-2 {
        top: 30% !important;
        left: -1% !important;
        z-index: 0 !important;
        transform: scale(3) rotate(-50deg) !important;
    }

    #tunisia-trainings-bottom-right-strie-1{
        top: 80% !important;
        left: 96% !important;
        z-index: -1 !important;
        transform: scale(3) rotate(-50deg) !important;
    }

    #tunisia-trainings-bottom-right-strie-2{
        top: 96% !important;
        left: 96% !important;
        z-index: -1 !important;
        transform: scale(7) rotate(-50deg) !important;
    }
}


@media (min-width: 2000px){
    #tunisia-trainings-top-left-strie-1 {
        top: 10% !important;
        left: 0% !important;
        transform: scale(8) rotate(-50deg) !important;
    }
    #tunisia-trainings-top-left-strie-2 {
        top: 30% !important;
        left: -1% !important;
        z-index: 0 !important;
        transform: scale(4) rotate(-50deg) !important;
    }

    #tunisia-trainings-bottom-right-strie-1{
        top: 85% !important;
        left: 96% !important;
        z-index: -1 !important;
        transform: scale(4) rotate(-50deg) !important;
    }

    #tunisia-trainings-bottom-right-strie-2{
        top: 103% !important;
        left: 96% !important;
        z-index: -1 !important;
        transform: scale(8) rotate(-50deg) !important;
    }
}


