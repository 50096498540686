.tabs-container {
  display: flex;
  flex-direction: column;
}

.tabs-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom: 1px solid #e5e5e5;
  text-wrap: nowrap;
}

.tab {
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

.tab-selected {
  font-weight: bold;
  border-bottom: 2px solid #000;
}

.tab-content {
  padding: 2rem 0;
  display: flex;
  justify-content: center;
}

.tab-content-inner {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.tab-content-image {
  width: 100%;
  max-width: 650px;
  border-radius: 0.75rem;
  object-fit: cover;
}

.tab-content-description {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.tab-content-title {
  font-size: 1.5rem;
  font-weight: bold;
}
