
.morocco-hero-section-wrapper {
    position: relative;
    width: 100vw;
    height:90vh;
    background-position: center;
    gap: 8px;
    color: white;
  }
  
  .morocco-hero-section-wrapper > * {
    margin: 0;
  }
  
  .morocco-hero-section-background {
    width: 200%;
    background-size: cover;
  }
  
  .morocco-hero-section-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    /* border: orange solid; */
  }
  .morocco-hero-section-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap: 1vh;
    margin-bottom: 15%;
    /* padding-bottom: 80px; */
  }

  .morocco-hero-section-titles-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:10px;
   
  }

  .morocco-hero-section-title{
    text-wrap: nowrap;
    min-width: fit-content;
  }
  
  .morocco-hero-section-title, .morocco-hero-section-subtitle {
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 3.25rem;
    line-height: 110%;
  }

  .morocco-hero-section-btn{
    min-width: fit-content;
    text-wrap:nowrap;
  }

  @media (max-width:450px){
    .morocco-hero-section-titles-container{
        flex-direction: column;
        align-items: center;
    }
  }

  .morocco-hero-section-btn {
    font-weight: bold;
    font-size: 20px;
    color: white;
    background: linear-gradient(
      to right,
      #6f483f,
      var(--primary-color-safety-orange)
    );
    padding: 15px 20px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .morocco-hero-section-btn:hover {
    background: linear-gradient(to right, #a56151, var(--primary-color-safety-orange));
  }
  
  .morocco-hero-section--logo {
    border-radius: 50px;
    font-size: small;
  }
  
  .morocco-hero-section-wrapper {
    position: relative;
    overflow: hidden;
  }

  /* ------------------------------------------------------------ ANIMATIONS -------------------------------------------------------------------- */
  @keyframes hero-section-body-fade-up{
    0% {
        opacity: 0.3;
        transform: translateY(40px) scale(0.95);
    }
    100% {
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
}

.morocco-hero-section-body.visible {
    animation: hero-section-body-fade-up 1.5s ease-in-out forwards;
  }

  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */

  @media (min-width: 800px){
    .morocco-hero-section-title{
        line-height: 110%;
    }
    .morocco-hero-section-subtitle{
        line-height: 110%;
    }

    .morocco-hero-section-titles-container{
        flex-direction: column;
        gap:8px;
        align-items: center;
    }

    .morocco-hero-section-container{
        flex-direction: row;
        justify-content: end;
    }

    .morocco-hero-section-body{
        gap:1rem;
        margin-right: 48px;
        margin-bottom: 0;
    }
  }

  @media (min-width:960px){
    .morocco-hero-section-body{
        margin-right:min(10vw, 150px);
    }
  }



  @media (min-width:1440px){
    .morocco-hero-section-titles-container{
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .morocco-hero-section-body{
        width:40dvw;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:24px;
    }

    .morocco-hero-section-title, .morocco-hero-section-subtitle{
        font-size: 3.75rem;
    }

    .morocco-hero-section-slogan{
        font-size: 1.75rem;
    }

    .morocco-hero-section-btn{
        font-size: 22px;
        padding: 1.25rem 1.25rem;
    }

  }

  @media (min-width:1680px){
    .morocco-hero-section-titles-container{
      flex-direction: row;
      justify-content: center;
    }

    .morocco-hero-section-title, .morocco-hero-section-subtitle{
        font-size: 4rem;
    }

    .morocco-hero-section-slogan{
        font-size: 2rem;
    }
  }

  @media (min-width:1920px){
    .morocco-hero-section-title, .morocco-hero-section-subtitle{
        font-size: 4.5rem;
    }

    .morocco-hero-section-slogan{
        font-size: 2.2rem;
    }

    .morocco-hero-section-btn{
        font-size: 24px;
        padding: 1.5rem 2rem;
    }

  }

