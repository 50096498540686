.about-us-context-box-description {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  width: 90%;
  min-height: 70px;
  padding: 0.5vh 1vh;
  border-radius: 15px;
  text-align: center;
  p {
    font-size: 20px;
  }
}

.about-us-context-box {
  --about-us-section-context-box-bg-color: white;
  --about-us-section-context-box-title-color: #00213b;
  padding: 30px;
  background-color: var(--about-us-section-context-box-bg-color);
  /* background: linear-gradient(to bottom, var(--about-us-section-context-box-bg-color) 200px, transparent 800px 100%); */

  box-sizing: border-box;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 1rem;

  border-radius: 15px;
  font-size: 1.1rem;
  z-index: 2;
}

.fade-in-from-right {
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-in-from-right.visible {
  opacity: 1;
  background: linear-gradient(
    to right,
    #6f483f,
    var(--primary-color-safety-orange)
  );
}
.fade-in-from-right.visible:hover {
  background: linear-gradient(
    to right,
    #a56151,
    var(--primary-color-safety-orange)
  );
}

.about-us-context-box-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--about-us-section-context-box-title-color);
}

@media (min-width: 550px) {
  .about-us-context-box-title {
    font-size: 1.8rem;
  }

  .fade-in-from-right {
    transform: translateX(0) translateY(50%);
  }

  .fade-in-from-right.visible {
    transform: translateX(-20%) translateY(50%);
  }

  .about-us-context-box {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 0;
    padding: 25px;
    min-height: 70%;
    font-size: 1rem;
  }
}

@media (min-width: 768px) {
  .about-us-context-box-title {
    font-size: 1.8rem;
  }
}

@media (min-width: 1024px) {
  .about-us-context-box-title {
    font-size: 2.2rem;
  }

  .about-us-context-box {
    max-width: 600px;
    min-width: 40%;
    font-size: 1.3rem;
  }
}

@media (min-width: 1440px) {
  .fade-in-from-right {
    transform: translateX(0) translateY(65%);
  }

  .fade-in-from-right.visible {
    transform: translateX(-20%) translateY(65%);
  }

  .about-us-context-box {
    min-width: 40%;
    max-width: 35%;
  }
}

@media (min-width: 1536px) {
  .fade-in-from-right {
    transform: translateX(0) translateY(65%);
  }

  .fade-in-from-right.visible {
    transform: translateX(-20%) translateY(65%);
  }

  .about-us-context-box {
    min-width: 25%;
    max-width: 35%;
  }
}

@media (min-width: 1920px) {
  .fade-in-from-right {
    transform: translateX(0) translateY(50%);
  }

  .fade-in-from-right.visible {
    transform: translateX(-20%) translateY(50%);
  }

  .about-us-context-box {
    min-width: 30%;
    min-height: 80%;
  }
}

@media (min-width: 2200px) {
  .fade-in-from-right {
    transform: translateX(0) translateY(50%);
  }

  .fade-in-from-right.visible {
    transform: translateX(-20%) translateY(70%);
  }

  .about-us-context-box {
    min-width: 30%;
    min-height: 80%;
  }
}
