.hero-section-wrapper {
    position: relative;
    width: 100vw;
    height:80vh;
    background-position: center;
    gap: 8px;
    color: white;
  }
  
  .hero-section-wrapper > * {
    margin: 0;
  }
  
  .hero-section-background {
    width: 200%;
    background-size: cover;
  }
  
  .hero-section-container {
    position: absolute;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    /* border: orange solid; */
  }
  .hero-section-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap: 1vh;
    margin-right:100px;
    margin-left: 100px;
    /* padding-bottom: 80px; */
  }
  
  .hero-section-title {
    color: white;
    line-height: 90px;
    text-align: center;
    font-weight: bold;
    font-size: 70px;
  }

  .hero-section-slogan{
    text-align: center;
  }

  .hero-section-cta {
    font-weight: bold;
    font-size: 20px;
    color: white;
    background: linear-gradient(
      to right,
      #6f483f,
      var(--primary-color-safety-orange)
    );
    /* padding: 10px 20px; */
    padding: 15px 20px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .hero-section-cta:hover {
    background: linear-gradient(to right, #a56151, var(--primary-color-safety-orange));
  }
  
  .hero-section-cta-logo {
    border-radius: 50px;
    font-size: small;
  }
  
  .hero-section-wrapper {
    position: relative;
    overflow: hidden;
  }

  /* ------------------------------------------------------------ ANIMATIONS -------------------------------------------------------------------- */
  @keyframes hero-section-body-fade-up{
    0% {
        opacity: 0.3;
        transform: translateY(40px) scale(0.95);
    }
    100% {
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
}

.hero-section-body.visible {
    animation: hero-section-body-fade-up 1.5s ease-in-out forwards;
  }

  /* ----------------------------------------------------------------------------------------------------------------------------------------------- */
  @media (max-width: 768px) {
    .hero-section-container {
      align-items: end;
      justify-content: center;
    }
    .hero-section-body {
      /* padding-bottom: 0px; */
      padding-bottom: 80px;
      margin: 0;
    }
    .hero-section-title {
      line-height: 70px;
      font-size: 50px;
    }
    .hero-section-slogan {
      font-size: 30px;
    }

    .parallax-image-container img{
       background-position: center;
    }
  }
  @media (max-width: 720px) {
    .hero-section-container{
      /* background-color: rgba(0, 0, 0, 0.4); */

    }
    .hero-section-title{
      color: #fff;
    }
  }
  

  @media (min-width:1440px){
    .hero-section-body {
      width: 30dvw;
    }
  }