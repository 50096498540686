/* Style général du conteneur de l'élément du panier */
.cart-element-image {
  display: grid;
  grid-template-columns: 40% 60%;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
  margin: 1em 0em;
  padding: 0.5em;
  border-radius: 10px;
  direction: ltr !important;
  .cart-img{
    border-radius: 10px;
  }
}

/* Ajoute un effet de survol */
.cart-element-image:hover {
  transform: translateY(-5px);
}

/* Style de l'image */
.cart-element-image img {
width: 100%;
height: 100%;
  object-fit: cover;
  border-radius: 10px;

}
select{
  color: #00213B;
padding: 0.5em;
border: 1px solid #e5e6e6c5;
outline: #00213B;
}
@media (max-width: 768px) {
  .cart-element-image{

    display: flex;
    flex-direction: column;
  }
}


/* Détails de l'élément */
.cart-element-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
}

/* Titre de l'élément */
.cart-element-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-top: 15px;
}

/* Prix de l'élément */
.cart-element-price {
  font-size: 16px;
  color: #007bff;
  margin: 4px 0;
}

/* Durée de l'élément */
.cart-element-duration {
  font-size: 14px;
  color: #666;
}

/* Actions de l'élément */
.cart-element-actions {
  margin-top: 12px; /* Espacement au-dessus des actions */
}

/* Style du bouton de suppression */
.remove-button {
  background-color: #e74c3c; /* Couleur rouge pour le bouton */
  color: white; /* Couleur du texte en blanc */
  border: none; /* Pas de bordure */
  border-radius: 4px; /* Coins arrondis pour le bouton */
  padding: 10px 16px; /* Espacement interne */
  cursor: pointer; /* Changement du curseur au survol */
  transition: background-color 0.3s;
  margin-bottom: 15px;
}

/* Effet de survol pour le bouton */
.remove-button:hover {
  background-color: #c0392b; /* Couleur rouge plus foncé au survol */
}
.cart-element-date {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: center;
  flex-wrap: wrap;
  width: 37%;
}
.date-select {
  max-width: none;
  width: 51%;
  height: 25px;
}
