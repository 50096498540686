.banner-mali {
  padding: 50px 0; /* Reduce padding to move content closer to the edges */
  margin-top: 130px;
  background: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ensure the banner takes the full viewport height */
}
#home {
  overflow: hidden;
}
.banner-row-mali {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Optional: Limit the maximum width of the content */
  margin: 0 auto; /* Center the content horizontally */
}

.text-col-mali {
  text-align: left;
  flex: 0 0 40%; /* Adjusts the width of the text column */
  padding-right: 15px; /* Optional: Add padding to the right */
}

.image-col-mali {
  text-align: right;
  flex: 0 0 60%; /* Adjusts the width of the image column */
  padding-left: 15px; /* Optional: Add padding to the left */
}

.tagline-mali {
  font-size: 1.5rem;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 20px;
}

h1 {
  /* font-size: 2.5rem;  */
  /* font-weight: bold; */
  /* color: rgb(29, 28, 28); */
  /* margin-bottom: 20px; */
}

.txt-rotate-mali {
  display: inline-block;
  color: black;
}

.wrap-mali {
  border-right: 0.08em solid #666;
}

/* p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 20px;
    color: #666;
  } */

/* button {
    color: black;
  } */
/*   
  button:hover {
    background-color: #0056b3;
  }
   */

img {
  /* max-width: 90%;  */
  /* height: auto;
    display: block;
    margin: 0 auto; */
}
