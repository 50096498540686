/* Fade and Slide-In from Left */


.fade-slide-in-left {
    opacity: 0;
    transform: translateX(-50px);
    transition: transform 1s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1s ease-in-out;
}
  
.fade-slide-in-left.visible {
    opacity: 1;
    transform: translateX(0);
}

  
/* Additional styles for delay */
.delay-1 {
    transition-delay: 0.2s;
}
  
.delay-2 {
    transition-delay: 0.4s;
}

.delay-3 {
    transition-delay: 0.6s;
}

.delay-4 {
    transition-delay: 0.8s;
}

.delay-5 {
    transition-delay: 1s;
}

.delay-6 {
    transition-delay: 1.2s;
}

.delay-7 {
    transition-delay: 1.4s;
}

.delay-8 {
    transition-delay: 1.6s;
}

.delay-9 {
    transition-delay: 1.8s;
}

.duration-0 {
    transition-duration: 200ms;
}

.duration-1 {
    transition-duration: 400ms;
}

.duration-2 {
    transition-duration: 600ms;
}

.duration-3 {
    transition-duration: 800ms;
}

.duration-4 {
    transition-duration: 1000ms;
}

.duration-5 {
    transition-duration: 1200ms;
}

.duration-6 {
    transition-duration: 1400ms;
}

.duration-7 {
    transition-duration: 1600ms;
}

.duration-8 {
    transition-duration: 1800ms;
}

.duration-9 {
    transition-duration: 2000ms;
}

.duration-10 {
    transition-duration: 2200ms;
}

.duration-11 {
    transition-duration: 2400ms;
}

.duration-12 {
    transition-duration: 2600ms;
}

.duration-13 {
    transition-duration: 2800ms;
}

.duration-14 {
    transition-duration: 3000ms;
}





  