@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");

.wrapper {
  display: flex !important;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}

.sliderImg {
  box-sizing: border-box;
  /* width: 80vw; */
  width:80%;
  aspect-ratio: 16/10;
  padding: 0px 2vw;
  object-fit: cover;
}

.slider-container .slick-dots {
  /* bottom:-25px; */
  bottom:-35px;
}

.slider-container .slick-dots li button::before {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
}

.slider-container .slick-dots li.slick-active button:before {
  color: rgba(0, 0, 0, 0.9) !important;
}

.imgTitle {
  font-weight: 900;
}

.slides .slick-prev,
.slides .slick-next {
  /* position: absolute; */
  /* top: 50%; */
  /* z-index: 2; */
  /* background-color: black; */
  background-color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 33, 59, 0.5) !important;
  color: white !important;
  font-size: 1.5rem;
  border: none;
  padding: 1rem;
  cursor: pointer;
  z-index: 1;
  transition: all 0.3s;
  width:36px;
  height:36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.slides .slick-prev:before,
.slides .slick-next:before {
 display: none;
}

.slides .slick-prev {
  left: 5%;
}

.slides .slick-next {
  right: 5%;
}

.carousel-3-btn {
  background-color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 33, 59, 0.5);
  color: white;
  font-size: 1.5rem;
  border: none;
  padding: 1rem;
  cursor: pointer;
  z-index: 1;
  transition: all 0.3s;
  width:36px;
  height:36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-3-btn:hover {
  background-color: var(--primary-color-harvest-gold) !important;
}

.carousel-3-btn:hover svg{
  color: white;
}

.slider-container{
  position: relative;
  display: flex;
  flex-direction: column;
}

.slider-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

}

.slider-content {
  margin-top: 50px;
  width:76%;
}

.slider-content-description-btn-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media (max-width:550px){
    .slides .slick-prev {
        left: 0;
      }
      
      .slides .slick-next {
        right: 0%;
      }
}