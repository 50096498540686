.footer-wrapper {
  position: relative;
  width: 100%;
}

.footer-top1 {
  position: absolute;
  z-index: -1;
  top: -55px;
  width: 100%;
  height: 200px;
  /* background-color: #497f58; */
  background-color: #00b532;
  clip-path: polygon(0 0, 0 32%, 100% 34%);
  z-index: 2;
}

.footer-top2 {
  position: absolute;
  z-index: -1;
  top: -55px;
  width: 100%;
  height: 200px;
  /* background-color: #32714e; */
  background-color: #06912c;
  clip-path: polygon(0 13%, 0 32%, 100% 34%);
  z-index: 2;
}

.footer-container {
  position: relative;
  display: flex;
  flex-direction: column;
  /* background-color: #005238; */
  background-color: #2d731d;
  padding: 16px;
  padding-top: 32px;
  z-index: 4;
}

.footer-body {
  display: flex;
  justify-content: space-around;
  position: relative;
  /* width: 100%; */
}

.social-networks-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.social-networks-title {
  color: white;
}
.social-networks-icons-container {
  display: flex;
  gap: 16px;
}

.plan-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.plan-title {
  color: white;
}
.plan-body {
  position: relative;
  display: flex;
  flex-direction: column;
  /*gap: 64px;*/
  z-index: 10;
  align-items: center;
}
.plan-column {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.plan-link {
  text-decoration: none;
}
.plan-text {
  color: white;
  font-size: 1.2rem;
}

.info-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}
.info-title {
  color: white;
}
.info-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 2;
  /* border: red solid; */
  align-items: center;
}
.info-link {
  text-decoration: none;
}
.info-text {
  color: white;
  font-size: 1.2rem;
}

.footer-adress-responsive {
  display: none;
}

.footer-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 200px;
  /* border: red solid; */
}

.logo-footer-image {
  width: 150px;
}
.footer-text {
  color: white;
  font-size: 1.1rem;
}
.logo-qualiopi {
  width: 60%;
}

.accessibility-link-container {
  display: flex;
  margin: 0 auto;
  gap: 0.5rem;
}

@media (max-width: 768px) {
  .footer-container {
    gap: 8px;
  }
  .footer-body {
    flex-direction: column;
    gap: 32px;
  }
  .social-networks-container {
    gap: 8px;
    align-items: center;
  }
  .social-networks-title {
    /* text-decoration: underline; */
    font-size: 1.8rem;
  }
  .social-networks-icons-container {
    width: 100%;
    justify-content: space-around;
  }

  .plan-container {
    gap: 8px;
    align-items: center;
  }
  .plan-title {
    /* text-decoration: underline; */
    font-size: 1.8rem;
  }
  .plan-body {
    width: 100%;
    justify-content: space-around;
    z-index: 1;
  }

  .info-container {
    gap: 8px;
    align-items: center;
  }
  .info-title {
    /* text-decoration: underline; */
    font-size: 1.8rem;
    text-align: center;
  }
  /* .info-body {
      width: 85%;
      flex-direction: column;
      flex-wrap: wrap;
      height: 80px;
      z-index: 1;
      align-items: center;
    } */

  .info-body {
    width: 85%;
    flex-direction: row;
    flex-wrap: wrap;
    height: 80px;
    z-index: 1;
    max-width: 350px;
    align-items: center;
    justify-content: space-between;
    justify-content: center;
    column-gap: 1rem;
  }

  #footer-accessibility-link {
    /* order:3;
      margin: 0 auto; */
  }

  .footer-footer {
    padding: 8px 16px;
    /* border: blue solid; */
  }

  .footer-adress {
    display: none;
  }

  .footer-adress-responsive {
    margin: 8px 0 0 0;
    text-align: center;
    display: block;
  }
  .logo-footer-image {
    width: 100px;
  }
}

@media (max-width: 550px) {
  .info-body {
    width: 95%;
    flex-direction: row;
    flex-wrap: wrap;
    height: fit-content;
    z-index: 1;
    align-items: center;
    justify-content: center;
    /* border: blue solid; */
  }

  #footer-accessibility-link {
    /* order:3; */
  }

  #contact-section-footer-link {
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .footer-footer {
    padding: 8px 16px;
    justify-content: space-around;
  }

  .footer-adress {
    /* text-wrap: balance; */
    width: 40%;
    /* border: blue solid; */
  }
}
