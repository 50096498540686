.algeria-features-section-wrapper {
  width: 100%;
  max-width: 100%;
  padding: 12rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f2f8;
}

@media (min-width: 2000px) {
  .algeria-features-section-wrapper {
    padding: 16rem 0;
  }
}

.algeria-features-section-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.algeria-features-section-title {
  font-size: 1.875rem;
  font-weight: 700;
  letter-spacing: -0.05em;
  line-height: 2.25rem;
  text-align: center;
}

.algeria-features-section-subtitle-description {
  max-width: 700px;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
}

.algeria-features-section-feature-cards-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

@media (min-width: 550px) {
  .algeria-features-section-feature-cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .algeria-features-section-title {
    font-size: 2.5rem;
    line-height: 120%;
  }
}

@media (min-width: 768px) {
  .algeria-features-section-feature-cards-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 290px);
    justify-items: center;
  }

  .algeria-features-section-subtitle-description {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1024px) {
  .algeria-features-section-subtitle-description {
    font-size: 1rem;
    line-height: 1.75rem;
  }

  .algeria-features-section-title {
    font-size: 2.25rem;
  }
  .algeria-features-section-feature-cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1440px) {
  .algeria-features-section-feature-cards-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1280px) {
  .algeria-features-section-container {
    width: 100%;
    /*max-width: 1200px;*/
  }

  .algeria-program-feature-container {
    max-width: 320px;
  }
}

@media screen and (min-width: 1920px) {
  /*.algeria-features-section-container {
    /*max-width: 1200px;
  }*/

  .algeria-features-section-subtitle-description {
    width: 60%;
  }
}

/* Styling feature items */
.algeria-program-feature-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 0.5rem;
}
.list-item a {
  cursor: pointer;
  color: black;
  transition: background-color 0.3s ease;
}

.list-item > a:hover {
  text-decoration: underline; /* Ajoute la décoration du texte au survol */
  color: blue;
}
.algeria-features-section-container.crea {
  width: 100%;
  align-items: start;
}
.algeria-program-feature-item-title {
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: -0.05em;
  line-height: 1.75rem;
  text-align: start;
  min-height: 1.75rem;
}

@media (min-width: 1280px) {
  .algeria-program-feature-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: start;
    gap: 0.5rem;
  }

  .algeria-program-feature-item-title {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.75rem;
    /* margin-bottom: auto; */
  }

  .algeria-program-feature-item-description {
    font-size: 1rem;
    line-height: 1.5rem;
    /* margin: auto 0; */
  }
}

@media (min-width: 1300px) {
  .algeria-features-section-title {
    font-size: 2.75rem;
    line-height: 120%;
  }
}

@media (min-width: 1500px) {
  .algeria-features-section-feature-cards-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1920px) {
  .algeria-features-section-subtitle-description {
    max-width: 65%;
  }

  .algeria-features-section-title {
    font-size: 3.75rem;
  }
}
