.language-dropdown {
  display: flex;
  flex-direction: column;
}
.language-dropdown button {
  background-color: transparent;
  font-size: 20px;
}
.language-switcher {
  height: 50px;
}
