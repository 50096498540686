.animated-button-1 {
    position: relative;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 16px 36px;
    border: 4px solid;
    border-color: transparent;
    font-size: 16px;
    background-color: inherit;
    border-radius: 100px;
    font-weight: 600;
    /* color: greenyellow; */
    /* box-shadow: 0 0 0 2px greenyellow; */
    color: #00213b;
    box-shadow: 0 0 0 2px #00213b;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button-1 svg {
    position: absolute;
    width: 24px;
    /* fill: greenyellow; */  /* couleur de l'icône (flèche) */
    fill: #00213b;
    z-index: 9;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button-1 .arr-1 {
    right: 16px;
  }
  
  .animated-button-1 .arr-2 {
    left: -25%;
  }
  
  .animated-button-1 .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    /* background-color: greenyellow; */
    background-color: #00213b;  /* couleur du bouton lors du survol */
    --bg-color : color-mix(in srgb, #6f483f 40%, var(--primary-color-safety-orange) 80%);
    --bg-color : linear-gradient(to right, #6f483f, var(--primary-color-safety-orange));
    background: var(--bg-color);
    border-radius: 50%;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button-1 .text {
    position: relative;
    z-index: 1;
    transform: translateX(-12px);
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .animated-button-1:hover {
    box-shadow: 0 0 0 12px transparent;
    color: white;        /* couleur du texte lors du survol */
    border-radius: 12px;
  }
  
  .animated-button-1:hover .arr-1 {
    right: -25%;
  }
  
  .animated-button-1:hover .arr-2 {
    left: 16px;
  }
  
  .animated-button-1:hover .text {
    transform: translateX(12px);
  }
  
  .animated-button-1:hover svg {
    fill: white;            /* couleur de l'icône lors du survol */
  }
  
  .animated-button-1:active {
    scale: 0.95;
    /* box-shadow: 0 0 0 4px greenyellow; */
    box-shadow: 0 0 0 4px #00213b;
  }
  
  .animated-button-1:hover .circle {
    width: 220px;
    height: 220px;
    opacity: 1;
  }
  