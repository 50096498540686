/*************************/
.avis-section-wrapper {
    position: relative;
    /* color: white; */
    color: #00213b;
    width: 100%;
    height: fit-content;
    min-height: 80vh;
    max-height:80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: #7ca289; */
    background-color:transparent;
    /* border: aqua solid; */
  }
  
  .avis-section-triangle {
    top: 0px;
    position: absolute;
    width: 70px;
    height: 60px;
    background: linear-gradient(to right, #6f3021, var(--primary-color-safety-orange));
    clip-path: polygon(100% 0, 0 0, 49% 55%);
  }
  
  .avis-section-container {
    /* width: 90%; */    
    min-width: min-content;
    max-width: 1400px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    gap: 8vh;
    text-align: center;
    /* border: red solid; */
  }
  
  .avis-section-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .avis-section-title {
    text-align: center;
  }
  
  .avis-section-stars {
    font-size: 20px;
    color: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 24px;
    /* border: gold solid; */
  }
  
  .avis-section-details {
    display: flex;
    justify-content: center;
    width: 90%;
    max-width: 800px;
    /* min-width: 400px; */
    height: fit-content;
    /* display: grid; */
    /* grid-template-columns: 1fr 1fr; */
    /* grid-template-rows: auto auto auto; */
    /* gap: 20px; */
    gap: 4vw;
    padding: 20px;
    /* border:  blue solid; */
    perspective: 1000px;
  }
  
  .avis-card-1 {
   }
  
  .avis-card-2 {
    transform: translateZ(160px);
  }
  
  .avis-card-3 {
  }

  .avis-card-1:hover {
    cursor: pointer;
    transition-duration: 1s;
    transform : translateZ(200px) translateX(40px);
  }

  .avis-card-3:hover {
    cursor: pointer;
    transition-duration: 1s;
    transform : translateZ(200px) translateX(-40px);
  }
  .avis-card-2:hover {
    cursor: pointer;
    transition-duration: 0.6s;
    transform : translateZ(240px);
  }
  
  
  .avis-card-4 {
  }
  
  .avis-card-5 {
  }

@media (max-width: 545px){
    .avis-section-wrapper {
        /* padding: 48px 0px; */
        padding: 120px 0;
        min-height: min-content;
        max-height: none;
    }
    
      .avis-section-details {
        max-width: 80%;
        display: flex;
        padding: 0;
        gap: 48px;
        flex-direction: column;
      }
      .avis-section-details > * {
        width: auto;
      }
  
      .avis-section-details .avis-card-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: auto;
      }

      .avis-section-container{
        gap:56px;
      }

      .avis-card-2 {
        transform: translateZ(0px);
      }

      .avis-card-1:hover{
        transform : translateZ(90px);
      }

      .avis-card-2:hover{
        transform : translateZ(90px);
      }

      .avis-card-3:hover{
        transform : translateZ(90px);
      }
  }


  

  
  @media screen and (min-width:545px) and (max-width: 768px) {
    .avis-section-wrapper{
        padding: 120px 0;
        min-height: min-content;
        max-height: none;
    }

    .avis-section-container{
        gap:56px;
      }

      .avis-card-2{
        transform: translateZ(100px);
      }


      .avis-card-1:hover{
        transform : translateZ(100px);
      }

      .avis-card-2:hover{
        transform : translateZ(100px);
      }

      .avis-card-3:hover{
        transform : translateZ(100px);
      }
}


@media screen and (min-width:768px){
    .avis-section-wrapper{
        padding: 120px 0 160px 0;
        min-height: min-content;
        max-height: none;
    }

    .avis-section-details{
        max-width: 900px;
    }

    .avis-card-container{
        aspect-ratio: 19/30;
    }
}