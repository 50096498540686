
.pitch-competition-mali {
    width: 100%;
    max-width: 100%;
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #f0f2f8; */
    animation: fadeIn 1.5s ease-in-out;
  }
  
  .pitch-competition-container-mali {
    width: 80%;
    max-width: 1400px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 1.5rem;
  }
  
  .pitch-competition-content-mali {
    max-width: 550px;
    width: 100%;
  }
  
  .pitch-competition-illustration-mali {
    width: 100%;
    flex: 1;
    border-radius: 10px;
    overflow: hidden; /* Ajouté pour s'assurer que l'image ne déborde pas */
   } 
  
  .pitch-competition-image-mali {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }
  
  .pitch-competition-image-mali:hover {
    transform: scale(1.05);
  }
  
  .pitch-competition-title-mali {
    font-size: 1.875rem;
    font-weight: bold;
    line-height: 1.2;
    color: #333;
  }
  
  .pitch-competition-description-mali {
    color: #666;
    line-height: 1.625;
    margin: 1rem 0;
  }
  
  .apply-button-mali {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .apply-button-mali:hover {
    background-color: #0056b3;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @media (min-width: 768px) {
    .pitch-competition-container-mali {
      width: 80%;
      max-width: 680px;
      flex-direction: row;
    }
  
    .pitch-competition-illustration-mali {
      max-width: 550px;
      min-height: 500px;
    }
  
    .pitch-competition-description-mali {
      font-size: 1.25rem;
      line-height: 1.625;
    }
  
    .pitch-competition-title-mali {
      font-size: 2.25rem;
    }
  
    .apply-button-mali {
      padding: 12px 24px;
    }
  }
  
  @media screen and (min-width: 1080px) {
    .pitch-competition-container-mali {
      width: 90%;
      max-width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  
    .pitch-competition-illustration-mali {
      min-height: 380px;
      max-height: 380px;
      min-width: 450px;
      max-width: 550px;
      aspect-ratio: 16/9;
    }
  
    .pitch-competition-content-mali {
      max-width: 500px;
    }
  }
  
  @media (min-width: 1300px) {
    .pitch-competition-content-mali {
      align-self: center;
    }
  
    .pitch-competition-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      max-width: 1400px;
    }
  
    .pitch-competition-content-mali {
      max-width: 550px;
    }
  }
  
  @media screen and (min-width: 1440px) {
    .pitch-competition-container-mali {
      width: 80%;
      max-width: 1400px;
    }
  }
   