.contact-wrapper {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../../media/images/pexels-photo-5448156.webp");
  background-position: center;
  background-size: cover;
  padding: 100px;
  /* background-repeat: no-repeat; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
  padding-bottom: 10%;
  /* border: orange solid; */
  /* z-index:1; */
}
