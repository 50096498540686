
@media screen and (max-width:545px) {
    #about-us-section-top-left-strie-1{
        top: 0 !important;
    }

    #about-us-section-top-left-strie-2{
        top: 20% !important;
    }

    #about-us-section-bottom-right-strie-1{
        top: 123% !important;
        z-index: -2 !important;
    }

    #about-us-section-bottom-right-strie-2{
        top: 135% !important;
    }
}

@media screen and (min-width: 545px) {
    #about-us-section-top-left-strie-1{
        top: 20% !important;
    }

    #about-us-section-top-left-strie-2{
        /* top: 40% !important; */
        top: 30% !important;
        z-index: -2 !important;
    }

    #about-us-section-bottom-right-strie-1{
        top: 100% !important;
        z-index: -2 !important;
    }

    #about-us-section-bottom-right-strie-2{
        top: 110% !important;
    }
    
}

@media screen and (min-width: 768px){

    #about-us-section-top-left-strie-1{
        top: 15% !important;
    }

    #about-us-section-top-left-strie-2{
        top: 35% !important;
    }

    #about-us-section-bottom-right-strie-1{
        top: 80% !important;
    }

    #about-us-section-bottom-right-strie-2{
        top: 80% !important;
    }
}

@media  screen and (min-width:768px) and (max-width: 1024px) {
    #about-us-section-top-left-strie-1{
        top: 15% !important;
    }

    #about-us-section-top-left-strie-2{
        top: 35% !important;
    }

    #about-us-section-bottom-right-strie-1{
        top: 78% !important;
    }

    #about-us-section-bottom-right-strie-2{
        top: 90% !important;
    }
}


@media screen and (min-width:920px){
    #about-us-section-top-left-strie-1{
        top: 15% !important;
    }

    #about-us-section-top-left-strie-2{
        top: 22% !important;
    }

    #about-us-section-bottom-right-strie-1{
        top: 82% !important;
    }

    #about-us-section-bottom-right-strie-2{
        top: 90% !important;
    }
}


@media screen and (min-width: 1024px){
    #about-us-section-top-left-strie-1{
        top: 15% !important;
        left: -6% !important;
        transform: scale(5.5) rotate(-50deg) !important;
    }

    #about-us-section-top-left-strie-2{
        top: 30% !important;
        left: -3% !important;

    }
    #about-us-section-bottom-right-strie-1{
        top: 46% !important;
        left: 95% !important;
    }

    #about-us-section-bottom-right-strie-2{
        top: 65% !important;
        transform: scale(7) rotate(-50deg) !important;
    }
}


@media screen and (min-width: 1200px){
    #about-us-section-top-left-strie-1{
        top: 10% !important;
        left: -5% !important;
        transform: scale(6) rotate(-50deg) !important;
    }

    #about-us-section-top-left-strie-2{
        top: 35% !important;
        left: -3% !important;
    }

    #about-us-section-bottom-right-strie-1{
        top: 50% !important;
    }

    #about-us-section-bottom-right-strie-2{
        top: 65% !important;
        transform: scale(7) rotate(-50deg) !important;
    }
}

@media screen and (min-width: 1440px){
    #about-us-section-top-left-strie-1{
        top: 0 !important;
        left: -5% !important;
        transform: scale(7) rotate(-50deg) !important;
    }

    #about-us-section-top-left-strie-2{
        top: 20% !important;
        left: -2% !important;
    }

    #about-us-section-bottom-right-strie-1{
        top: 54% !important;
        left: 96% !important;
    }

    #about-us-section-bottom-right-strie-2{
        top: 70% !important;
        transform: scale(7) rotate(-50deg) !important;
    }
}


@media screen and (min-width: 1920px){
    #about-us-section-top-left-strie-1{
        top: 0 !important;
        left: -5% !important;
        transform: scale(7) rotate(-50deg) !important;
    }

    #about-us-section-top-left-strie-2{
        top: 18% !important;
        left: -2% !important;
        z-index: -2 !important;
    }

    #about-us-section-bottom-right-strie-1{
        top: 55% !important;
        left: 97% !important;
    }

    #about-us-section-bottom-right-strie-2{
        top: 70% !important;
        transform: scale(7) rotate(-50deg) !important;
    }
}


@media (aspect-ratio:1024/600){
    #about-us-section-top-left-strie-1{

    }

    #about-us-section-top-left-strie-2{

    }

    #about-us-section-bottom-right-strie-2{
        top: 90% !important;
    }

    #about-us-section-bottom-right-strie-1{
        top: 50% !important;
    }
}

@media (aspect-ratio:1280/800){
    #about-us-section-top-left-strie-1{

    }

    #about-us-section-top-left-strie-2{

    }

    #about-us-section-bottom-right-strie-2{
        top: 90% !important;
        transform: scale(8) rotate(-50deg) !important;
    }

    #about-us-section-bottom-right-strie-1{
        top: 50% !important;
    }
}