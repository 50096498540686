
.about-us-section-wrapper{
    position:relative;
    margin: 12vh 0vh 32vh 0vh;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index:0;
}

.about-us-section-container{
    width:100%;
    height:400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap:0.3rem;
}

@media screen and (max-width: 545px) {
    .about-us-section-wrapper{
        margin: 10vh 0vh 18vh 0vh;
    }
    
}

@media (min-width: 550px){

    .about-us-section-wrapper{
        /* margin: 0vh 0vh 32vh 0vh; */
        margin: 0vh 0vh 8vh 0vh;
    }

    .about-us-section-container{
        width: 90%;
        margin:0;
        flex-direction: row;
        justify-content: space-between;
    }
  }

@media (min-width: 768px){
    .about-us-section-wrapper{
        margin: 6vh 0vh 6vh 0vh;
    }
}

@media (min-width: 1024px){

    .about-us-section-wrapper{
        margin: 12vh 0vh 12vh 0vh;
    }

    .about-us-section-container{
        width:1000px;
        min-width:1000px;
        /* max-width: 1200px; */
        max-width: 80%;
        /* height:400px; */
        height:450px;
        /* height:fit-content; */
        flex-direction: row;
        justify-content: center;
    }
}

@media (min-width: 1440px) {
    .about-us-section-wrapper{
        margin: 16vh 0vh 16vh 0vh;
        width:100%;
        min-width:1200px;
    }
    .about-us-section-container{
        width:90%;
    }   
}

@media (min-width: 1740px) {
    .about-us-section-wrapper{
        margin: 16vh 0vh 16vh 0vh;
        width:100%;
        min-width:1200px;
    }

    .about-us-section-container{
        width:90%;
        max-width: 1400px;
    }   
}

@media (min-width: 1920px) {

    .about-us-section-container{
        width:70%;
        min-width: 70%;
        max-width: 1600px !important;
    }   
}


@media screen and (min-width: 2200px) {
    .about-us-section-container{
        width:70%;
        max-width: 70% !important;
    } 
}


@media (aspect-ratio:1024/1366){
    .about-us-section-wrapper{
        margin: 16vh 0;
    }
}