@media (max-width:550px){
    #kids-section-top-left-strie-1{
    }

    #kids-section-top-left-strie-2{
    }

    #kids-section-top-right-strie-1{
    }

    #kids-section-bottom-right-strie-2{
        top: 26% !important;
        left: 100% !important;
        z-index: -2 !important;
    }
}

@media (min-width:550px) and (max-width:768px){
    #kids-section-top-left-strie-1{
        left: -10% !important;
        top: 95% !important;
    }

    #kids-section-top-left-strie-2{
        left: -10% !important;
        top: 85% !important;
    }

    #kids-section-top-right-strie-1{
    }

    #kids-section-bottom-right-strie-2{
        top: 26% !important;
        left: 100% !important;
        z-index: -2 !important;
    }
}

@media (min-width:768px) and (max-width:920px){
    #kids-section-top-left-strie-1{
        left:-10% !important;
        top: 95% !important;
    }

    #kids-section-top-left-strie-2{
        left: -10% !important;
        top: 90% !important;
    }

    #kids-section-bottom-right-strie-2{
        top: 22% !important;
        left: 100% !important;
        z-index: -2 !important;
    }
}

@media (min-width:920px) and (max-width:980px){
    #kids-section-top-left-strie-1{
        left:-10% !important;
        top: 95% !important;
    }

    #kids-section-top-left-strie-2{
        left: -10% !important;
        top: 90% !important;
    }

    #kids-section-top-right-strie-1{
        top: 0% !important;
        left: 100% !important;
    }

    #kids-section-bottom-right-strie-2{
        top: 26% !important;
        left: 100% !important;
        z-index: -2 !important;
    }
}

@media (min-width:980px) and (max-width:1024px){
    #kids-section-top-left-strie-1{
        left:-10% !important;
        top: 95% !important;
    }

    #kids-section-top-left-strie-2{
        left: -10% !important;
        top: 90% !important;
    }

    #kids-section-top-right-strie-1{
        top: 0% !important;
        left: 100% !important;
    }

    #kids-section-bottom-right-strie-2{
        top: 42% !important;
        left: 100% !important;
        z-index: -2 !important;
    }
}

@media (min-width:1024px) and (max-width:1440px){
    #kids-section-top-left-strie-1{
        left: -10% !important;
        top: 25% !important;
    }

    #kids-section-top-left-strie-2{
        left: -10% !important;
        top: 10% !important;
    }

    #kids-section-top-right-strie-1{
        top: 0% !important;
        left: 100% !important;
    }

    #kids-section-bottom-right-strie-2{
        display:none;
    }
}

@media (min-width:1440px) and (max-width:1740px){
    #kids-section-top-left-strie-1{
        left: -15% !important;
        top: 105% !important;
    }

    #kids-section-top-left-strie-2{
        left: -15% !important;
        top: 90% !important;
    }

    #kids-section-top-right-strie-1{
    }

    #kids-section-bottom-right-strie-2{
        display: none;
    }
}


@media (min-width:1740px) and (max-width:1920px){
    #kids-section-top-left-strie-1{
        left: -20% !important;
        top: 12% !important;

    }

    #kids-section-top-left-strie-2{
        left: -20% !important;
        top:0% !important;
    }

    #kids-section-top-right-strie-1{
        top: 0% !important;
        left: 100% !important;
    }

    #kids-section-bottom-right-strie-2{
        top: 28% !important;
        left: 110% !important;
        z-index: -2 !important;
    }
}

@media (min-width: 1920px){
    #kids-section-top-left-strie-1{
        left: -30% !important;
        top: 12% !important;

    }

    #kids-section-top-left-strie-2{
        left: -30% !important;
        top:0% !important;
    }

    #kids-section-top-right-strie-1{
        /* top: 0% !important; */
        /* left: 100% !important; */
    }

    #kids-section-bottom-right-strie-2{
        top: 14% !important;
        left: 115% !important;
        z-index: -2 !important;
    }
}

@media (min-width: 2020px){
    #kids-section-top-left-strie-1{
        left: -30% !important;
        top: 12% !important;

    }

    #kids-section-top-left-strie-2{
        left: -30% !important;
        top:0% !important;
    }

    #kids-section-top-right-strie-1{
        top: 0% !important;
        left: 105% !important;
    }

    #kids-section-bottom-right-strie-2{
        top: 0% !important;
        left: 125% !important;
        transform: scale(3.5) rotate(-50deg) !important;
        z-index: -2 !important;
    }
}