    /*  ADJUSTING STRIES POSITION FOR DIFFERENT SCREENS */
    
    @media screen and (max-width: 550px){
        #tunisia-intro-section-top-left-strie-1{
            top: 14% !important;
            left: -12% !important;
          }
          #tunisia-intro-section-bottom-right-strie-1{
              top: 85% !important; 
              left: 100% !important;
          }
    }

    @media screen and (max-width: 300px){
        #tunisia-intro-section-top-left-strie-1{
            top: 10% !important;
            left: -20% !important;
          }
          #tunisia-intro-section-1-bottom-right-strie-1{
              top: 88% !important; 
              left: 100% !important;
          }
    }

    @media screen and (min-width: 550px) and (max-width: 768px){

      #tunisia-intro-section-top-left-strie-1{
        top: 14% !important;
        left: -7% !important;
      }
      
      #tunisia-intro-section-bottom-right-strie-1{
        top: 85% !important; 
        left: 98% !important;
    }
    }

    @media  screen and (min-width:768px) and (max-width: 920px) {
        #tunisia-intro-section-top-left-strie-1{
            top: 13% !important;
            left: -3% !important;
          }
          #tunisia-intro-section-bottom-right-strie-1{
              top: 85% !important; 
              left: 95% !important;
          }
    }

    @media screen and (min-width: 920px) and (max-width: 1024px){
        #tunisia-intro-section-top-left-strie-1{
            top: 20% !important;
            left: -7% !important;
            transform: scale(7) rotate(-50deg) !important;
          }
          #tunisia-intro-section-bottom-right-strie-1{
              top: 78% !important; 
              left: 101% !important;
              transform: scale(7) rotate(-50deg) !important;
          }
    }

    
@media screen and (min-width: 1024px){
    #tunisia-intro-section-top-left-strie-1{
      top: 18% !important;
      left: -7% !important;
    }
    #tunisia-intro-section-bottom-right-strie-1{
        top: 80% !important;
        left: 100% !important;
    }
}

@media screen and (min-width: 1090px){
    #tunisia-intro-section-top-left-strie-1{
      top: 22% !important;
      left: -5% !important;
      transform: scale(4) rotate(-50deg) !important;
    }
    #tunisia-intro-section-bottom-right-strie-1{
        top: 73% !important;
        left: 97% !important;
        transform: scale(4) rotate(-50deg) !important;
    }
}

@media screen and (min-width: 1300px){
  #tunisia-intro-section-top-left-strie-1{
    top: 27% !important;
    left: -3% !important;
    transform: scale(5) rotate(-50deg) !important;
  }
  #tunisia-intro-section-bottom-right-strie-1{
      top: 70% !important;
      left: 95% !important;
      transform: scale(5) rotate(-50deg) !important;
  }
}


@media screen and (min-width: 1550px){
    #tunisia-intro-section-top-left-strie-1{
      top: 32% !important;
      left: -1% !important;
    }
    #tunisia-intro-section-bottom-right-strie-1{
        top: 70% !important; 
        left: 95% !important;
    }
}

@media screen and (min-width: 1650px){
    #tunisia-intro-section-top-left-strie-1{
      top: 32% !important;
      left: -1% !important;
      transform: scale(5.5) rotate(-50deg) !important;
    }
    #tunisia-intro-section-bottom-right-strie-1{
        top: 66% !important; 
        left: 96% !important;
        transform: scale(5.5) rotate(-50deg) !important;
    }
}

@media screen and (min-width: 1920px){
    #tunisia-intro-section-top-left-strie-1{
      top: 28% !important;
      left: -1% !important;
      transform: scale(6) rotate(-50deg) !important;
    }
    #tunisia-intro-section-bottom-right-strie-1{
        top: 66% !important;
        left: 96% !important;
        transform: scale(6) rotate(-50deg) !important;
    }
}

@media screen and (min-width: 2200px){
    #tunisia-intro-section-top-left-strie-1{
      top: 33% !important;
      left: 0% !important;
      transform: scale(7.5) rotate(-50deg) !important;
    }
    #tunisia-intro-section-bottom-right-strie-1{
        top: 66% !important;
        left: 98% !important;
        transform: scale(7.5) rotate(-50deg) !important;
    }
}


