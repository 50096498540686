.faq-section-wrapper {
    isolation: isolate;
    position: relative;
    background-color: white;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14vh 0;
    /* margin: 8vh 0 0 0; */
    gap: 2vh;
    /* border: aqua solid; */
  }
  
  .faq-section-container {
    position: relative;
    top: -100px;
    background-color: #edf1f7;
    border-radius: 15px;
    padding: 4vw;
    width: 80%;
    min-width: min-content;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    gap: 3vh;
    /* background-color: #e7d5ba; */
    /* beige: #e7d5ba */
    /* brown-red : #6f483f */
    /* --light-brown: color-mix(in srgb,#6f483f 50%, var(--primary-color-safety-orange) 50%);
    background-color: color-mix(in srgb, white 90%, var(--light-brown) 15%);
    --light-brown: color-mix(in srgb,#6f483f 80%, var(--primary-color-safety-orange) 20%);
    background-color: color-mix(in srgb, white 90%, var(--light-brown) 12%);
    --light-brown: color-mix(in srgb,var(--primary-color-selective-yellow) 20%, var(--primary-color-safety-orange) 80%);
    background-color: color-mix(in srgb, white 90%, var(--light-brown) 12%); */
    /* background-color: color-mix(in srgb, white 90%, #e7d5ba 20%); */

  /* border: greenyellow solid; */
  }

  .faq-section-container a{
    /* color: var(--secondary-color-bard-red); */
    color: #00213b;
    font-weight:600;
  }
  
  .faq-section-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* --light-brown: color-mix(in srgb,#6f483f 80%, var(--primary-color-safety-orange) 20%);
    color: var(--light-brown);  */
  }
  
  .faq-section-title {
    text-align: center;
        /* color: var(--secondary-color-pakistan-green);
    color: var(--secondary-color-bard-red); */
    /* --light-brown: color-mix(in srgb,#6f483f 80%, var(--primary-color-safety-orange) 20%);
    color: var(--light-brown);   */
  }
  
  .faq-section-body {
    width: clamp(200px, 85%, 800px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .faq-response {
    padding: 12px;
  }
  