.mentor-section-mali {
  text-align: center;
  padding: 20px;
}

.mentor-section-title-mali {
  font-size: 24px;
  margin-bottom: 20px;
}

.mentor-cards-mali {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.mentor-card-mali {
  background: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.mentor-image-mali {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-bottom: 10px;
  margin-right: 115px;
}

.mentor-name-mali {
  font-size: 18px;
  margin: 10px 0;
}

.mentor-title-mali {
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
}

.mentor-connect-button-mali {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
}

.mentor-connect-button-mali:hover {
  background-color: #0056b3;
}
