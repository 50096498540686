.tunisia-trainings-section--wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
    background-color: white;
    position: relative;
    z-index: 1;
}

.tunisia-trainings-section--container{
    /* width: 85%; */
    width: 90%;
    display: flex;
    justify-content: center;
    gap:2rem;
    flex-wrap: wrap;    
    perspective: 1000px;
}

@media (min-width:550px){
    .tunisia-trainings-section--container{
        max-width: min(75%, 450px);
    }
}

@media (min-width:768px){
    .tunisia-trainings-section--container{
        /* max-width: min(600px, 85%); */
        max-width: 95%;
        width: 95%;
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
    }
}

@media screen and (min-width: 1024px){
    .tunisia-trainings-section--container{
        max-width: 75%;
        column-gap: 2rem;
        row-gap: 2rem;
    }
}

@media screen and (min-width: 1280px){
    .tunisia-trainings-section--container{
    }
}

@media screen and (min-width: 1600px){
    .tunisia-trainings-section--container{
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        max-width: 1300px;
    }
}

@media screen and (min-width: 1920px){
    .tunisia-trainings-section--container{
        max-width: min(1500px, 75%);
    }
}


@media (min-width:1920px){

    .tunisia-trainings-section--wrapper{
        align-items: center;
    }
}

.tunisia-trainings-section--header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.75rem;
}

.tunisia-trainings-section--header-title{
    font-size: 2.5rem;
    line-height: 120%;
    font-weight: 700;
    /* letter-spacing: -0.05em; */
    text-align: center;
    max-width: 90%;
}

@media (max-width:550px){
    .tunisia-trainings-section--header-title{
        font-size: 1.875rem;
        font-weight: 700;
        letter-spacing: -0.05em;
        line-height: 2.25rem;
    }
}

@media (min-width:1024px){
    .tunisia-trainings-section--header-title{
        font-size: 2.25rem;
    }
}

@media screen and (min-width:1300px){
    .tunisia-trainings-section--header-title{
        font-size: 2.75rem;
        line-height:120%;
    }
}

@media screen and (min-width:1920px){
    .tunisia-trainings-section--header-title{
        font-size: 3.75rem;
    }
}

.tunisia-trainings-section--header-description{
    width: 70%;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
}

@media (max-width:550px){
    .tunisia-trainings-section--header-description{
        width: 80%;
        /* max-width:85%; */
    }
}


.tunisia-trainings-section--header-badge{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    /* padding: 0.35rem 1.25rem; */
    padding: 0.5rem 1.5rem;
    font-size: 1.25rem;
    background-color: #00213b;
    color: white;
    font-weight: 600;
    background: linear-gradient(
    to right,
    #6f483f,
    var(--primary-color-safety-orange)
    );
}
