.section-template-one-mali {
  width: 100%;
  max-width: 100%;
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f2f8;
  animation: fadeIn 1.5s ease-in-out;
}

.section-template-one-container-mali {
  width: 80%;
  max-width: 1400px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 1.5rem;
}

.section-template-one-content-mali {
  max-width: 550px;
  max-width: 100%;
}

.section-template-one-illustration-mali {
  min-width: 500px;
  max-height: 400px;
  max-width: 550px;
  min-width: 100%;
  max-width: 100%;
  aspect-ratio: 16/11;
  flex: 1;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.icon-mali {
  width: 1.25rem;
  height: 1.25rem;
}

.feature-title-mali {
  font-weight: 500;
}

.feature-description-mali {
  color: var(--text-muted-foreground);
}

.section-template-one-image-mali {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.section-template-one-image-mali:hover {
  transform: scale(1.05);
}

.section-template-one-text-mali {
  margin: 0.5rem 0;
}

.section-template-one-title-mali {
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 1.2;
  color: #333;
}

.section-template-one-features-mali {
  display: grid;
  gap: 1.5rem;
}

.feature-item-mali {
  display: flex;
  align-items: start;
  gap: 1rem;
  transition: transform 0.3s ease-in-out;
}

.feature-item-mali:hover {
  transform: translateY(-5px);
}

.feature-icon-mali {
  background-color: var(--bg-muted);
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  width: 2.5rem;
}

.section-template-one-description-mali {
  color: var(--text-muted-foreground);
  line-height: 1.625;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .section-template-one-container-mali {
    width: 80%;
    max-width: 680px;
  }

  .section-template-one-illustration-mali {
    max-width: 550px;
    min-height: 500px;
  }

  .section-template-one-description-mali {
    font-size: 1.25rem;
    line-height: 1.625;
  }

  .feature-icon-mali {
    width: 3rem;
  }

  .section-template-one-section-mali {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

@media screen and (min-width: 1080px) {
  .section-template-one-container-mali {
    width: 90%;
    max-width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .section-template-one-illustration-mali {
    min-height: 380px;
    max-height: 380px;
    min-width: 450px;
    max-width: 550px;
    aspect-ratio: 16/9;
  }

  .section-template-one-content-mali {
    max-width: 500px;
  }
}

@media (min-width: 1300px) {
  .section-template-one-content-mali {
    align-self: center;
  }

  .section-template-one-container-mali {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1400px;
  }

  .section-template-one-content-mali {
    max-width: 550px;
  }
}

@media screen and (min-width: 1440px) {
  .section-template-one-container-mali {
    width: 80%;
    max-width: 1400px;
  }
}
