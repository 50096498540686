.avis-card-container {
    background-color: white;
    border-radius: 16px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 12px;
    color: black;
    flex-basis: 25%;
    /* border: orange solid; */
  }
  
  .avis-card-container-picture {
    width: 100px;
    border-radius: 5px;
    aspect-ratio: 1/1;
  }
  
  .avis-card-container-description {
    /* height: 60px ; */
    text-align: left;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    /* border: red solid; */
  }
  
  .avis-card-separator {
    width: 80%;
    margin: 0;
    /* border: red solid 1px; */
  }
  
  .avis-section-stars {
    width: fit-content;
    /* border: black solid; */
  }
  .avis-section-stars > .filled {
    color: #edc382;
  }
  
  .avis-card-details {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  