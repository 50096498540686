/* Formation Section Styles */
.section-formation-senegal {
    width: 100%;
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
  }
  
  .section-formation-container-senegal {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
    width: 80%;
    max-width: 1400px;
  }
  
  .formation-card-senegal {
    background-color: #f0f2f8;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease-in-out;
  }
  
  .formation-card-senegal:hover {
    transform: scale(1.05);
  }
  
  .formation-image-senegal {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .formation-content-senegal {
    padding: 1rem;
    text-align: center;
  }
  
  .formation-title-senegal {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.5rem 0;
  }
  
  .formation-description-senegal {
    color: #666;
    margin-bottom: 1rem;
  }
  
  .formation-button-senegal {
    padding: 0.5rem 1rem;
    background: linear-gradient(
      to right,
      #6f483f,
      var(--primary-color-safety-orange)
    );
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .formation-button-senegal:hover {
    background: linear-gradient(
      to right,
      #241512,
      var(--primary-color-safety-orange)
    );
  }
  