.algeria-section-1-wrapper {
  width: 100%;
  max-width: 100%;
  /* padding: 5rem 0; */
  padding:8rem 0;
  display:flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #f0f2f8; */
  background-color: white;
  position: relative;
}

@media (min-width: 2000px) {
  .algeria-section-1-wrapper {
      padding: 16rem 0;
  }
}

.algeria-section-1-container {
  width: 80%;
  max-width: 1400px;
  display:flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  z-index:2;
}


.algeria-section-1-content{
  max-width:550px;
  max-width: 100%;
 }

.algeria-section-1-illustration {
  min-width:500px;
  max-height:400px;
  max-width: 550px;
  min-width: 100%;
  max-width: 100%;
  aspect-ratio: 16/11;
  flex:1;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
}

.algeria-section-feature-title {
  font-weight: 500;
}

.algeria-section-feature-description {
  color: var(--text-muted-foreground);
}


.algeria-section-1-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}


.algeria-section-1-text {
  margin: 0.5rem 0;
}

.algeria-section-1-title {
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width:550px){
  .algeria-section-1-title {
    font-size: 2.5rem;
  }
}

  
.algeria-section-1-features {
  display: grid;
  gap: 1.5rem;
}

.algeria-section-feature-item {
  display: flex;
  align-items: start;
  gap: 1rem;
}

.algeria-section-feature-icon {
  background-color: var(--bg-muted);
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  width: 2.5rem;
}

.algeria-section-1-description {
  color: var(--text-muted-foreground);
  line-height: 1.625;
}


@media (min-width: 768px) {
  .algeria-section-1-container {
      width: 80%;
      max-width: 680px;
  }

  .algeria-section-1-illustration {
      max-width: 550px;
      min-height:500px;
  }

  .algeria-section-1-description {
    font-size: 1.25rem;
    line-height: 1.625;
  }

  .algeria-section-feature-icon {
      width: 3rem;
  }

}

@media (min-width:1024px){
  .algeria-section-1-title{
    font-size: 2.25rem;
  }
}

@media screen and (min-width: 1080px){
  
  .algeria-section-1-container {
      width: 85%;
      max-width: 85%;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
      gap: 44px;
    }

  .algeria-section-1-illustration {
      min-height: 400px;
      max-height:400px;
      min-width: 50%;
      /* max-width: 550px; */
      max-width: min(550px, 50%);
      aspect-ratio: 16/9;
  }

  .algeria-section-1-content{
      max-width: 500px;
  }

}

@media (min-width: 1300px) {

  .algeria-section-1-container {
    max-width: 75%;
  }

  .algeria-section-1-content{
      max-width:45%;
  }

  .algeria-section-1-title{
    font-size: 2.75rem;
    line-height: 120%;
  }
}

@media screen and (min-width:1440px){
.algeria-section-1-container {
  gap:4rem;
  gap:48px;
}
}

@media screen and (min-width: 1920px){
.algeria-section-1-container {
  max-width: min(1700px, 75%);
  gap:84px;
}

.algeria-section-1-title {
  font-size: 3.75rem;
}

.algeria-section-1-description {
  font-size: 2rem;
  line-height: 130%;
}

.algeria-section-feature-title {
  font-size: 1.75rem;
}

.algeria-section-feature-description {
  font-size: 1.25rem;
}

.algeria-section-1-illustration {
  min-height: 500px;
}
}

/* ANIMATIONS */
/* Animation for the illustration */
.algeria-section-1-illustration{
  opacity: 0;
  transform: scale(0.6);
  transition: all 0.9s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.algeria-section-1-illustration.visible{
  opacity: 1;
  transform: scale(1);
}
/* Animation for the content of the section */
/* Animation for the content section feature items */
#algeria-section-feature-item-1{
  opacity: 0;
  /* transform:  translateX(50px) translateY(35px); */
  transform:  translateX(50px) scale(0.6);
  transition: all 1s cubic-bezier(0.25, 0.1, 0.25, 1);
}
#algeria-section-feature-item-1.visible{
  opacity: 1;
  transform:  translateX(0) translateY(0);
}

#algeria-section-feature-item-2{
  opacity: 0;
  /* transform:  translateX(50px) translateY(35px); */
  transform:  translateX(50px) scale(0.6);
  transition: all 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-delay: 0.3s;
}
#algeria-section-feature-item-2.visible{
  opacity: 1;
  transform:  translateX(0) translateY(0);
}

#algeria-section-feature-item-3{
  opacity: 0;
  /* transform:  translateX(50px) translateY(35px); */
  transform:  translateX(50px) scale(0.6);
  transition: all 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-delay: 0.6s;
}
#algeria-section-feature-item-3.visible{
  opacity: 1;
  transform:  translateX(0) translateY(0);
}