

@media screen and (max-width: 550px){
    
    #tunisia-section-2-top-left-strie-1{
        top: 9% !important;
        left: 0% !important;
        transform: scale(2.5) rotate(-50deg) !important;
        z-index: 0;
    }
    
    #tunisia-section-2-top-left-strie-2{
        top: 17% !important;
        left: -5% !important;
        transform: scale(1.5) rotate(-50deg) !important;
        z-index: 0;
    }
    }
    
    @media screen and (min-width: 550px) {
        #tunisia-section-2-top-left-strie-1{
            top: 15% !important;
            left: -5% !important;
        }
        
        #tunisia-section-2-top-left-strie-2{
            top: 27% !important;
            left: -7% !important;
        }
    }
    
    @media screen and (min-width: 820px) {
        #tunisia-section-2-top-left-strie-1{
            top: 14% !important;
            left: -3% !important;
        }
        
        #tunisia-section-2-top-left-strie-2{
            top: 27% !important;
            left: -5% !important;
        }
    }
    
    @media screen and (min-width: 920px) {
        #tunisia-section-2-top-left-strie-1{
            top: 16% !important;
            left: -3% !important;
        }
        
        #tunisia-section-2-top-left-strie-2{
            top: 25% !important;
            left: -2% !important;
        }
    }
    
    @media screen and (min-width: 1024px) {
        #tunisia-section-2-top-left-strie-1{
            top: 16% !important;
            left: -2% !important;
            transform: scale(4.5) rotate(-50deg) !important;
        }
        
        #tunisia-section-2-top-left-strie-2{
            top: 30% !important;
            left: -3% !important;
            transform: scale(2.5) rotate(-50deg) !important;
        }
    }
    
    @media screen and (min-width: 1080px) {
        #tunisia-section-2-top-left-strie-1{
            top: 22% !important;
            left: -5% !important;
            transform: scale(4.5) rotate(-50deg) !important;
        }
        
        #tunisia-section-2-top-left-strie-2{
            top: 42% !important;
            left: -6% !important;
            transform: scale(2.5) rotate(-50deg) !important;
        }
    }
    
    
    @media screen and (min-width: 1300px) {
        #tunisia-section-2-top-left-strie-1{
            top: 26% !important;
            left: 0 !important;
            transform: scale(5) rotate(-50deg) !important;
        }
        
        #tunisia-section-2-top-left-strie-2{
            top: 52% !important;
            left: -2% !important;
        }
    }
    
    @media screen and (min-width: 1800px) {
        #tunisia-section-2-top-left-strie-1{
            top: 32% !important;
            left: -2 !important;
            transform: scale(6) rotate(-50deg) !important;
        }
        
        #tunisia-section-2-top-left-strie-2{
            top: 63% !important;
            left: -2% !important;
            transform: scale(2.75) rotate(-50deg) !important;
        }
    }
    
    
    @media screen and (min-width: 1920px) {
        #tunisia-section-2-top-left-strie-1{
            top: 27% !important;
            left: -2 !important;
            transform: scale(8.5) rotate(-50deg) !important;
        }
        
        #tunisia-section-2-top-left-strie-2{
            top: 52% !important;
            left: 1% !important;
            transform: scale(4) rotate(-50deg) !important;
        }
    }