.tunisia-features-section-wrapper{
    width: 100%;
    max-width: 100%;
    padding:12rem 0;
    display:flex;
    flex-direction:column;
    align-items: center;
    background-color: #f0f2f8;
}

@media (min-width: 2000px) {
    .tunisia-features-section-wrapper {
        padding: 16rem 0;
    }
  }
  

.tunisia-features-section-container{
    width:80%;
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:2rem;
}

.tunisia-features-section-title{
    font-size: 1.875rem;
    font-weight: 700;
    letter-spacing: -0.05em;
    line-height: 2.25rem;
    text-align: center;
}

.tunisia-features-section-subtitle-description{
    max-width: 700px;
    font-size: 1rem;
    line-height: 1.5rem;
}

.tunisia-features-section-feature-cards-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

@media (min-width:550px){
    .tunisia-features-section-feature-cards-container{
        display:grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .tunisia-features-section-title{
        font-size: 2.5rem;
        line-height: 120%;
    }

}

@media (min-width:768px){

    .tunisia-features-section-feature-cards-container{
        width: 100%;
        display:grid;
        grid-template-columns: repeat(3, 1fr);
    }


    .tunisia-features-section-subtitle-description{
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

}

@media (min-width:1024px){
    .tunisia-features-section-subtitle-description{
        font-size: 1rem;
        line-height: 1.75rem;
    }

    .tunisia-features-section-title{
        font-size: 2.25rem;
    }
}

@media (min-width:1280px) {

    .tunisia-features-section-container{
        width: 60%;
        max-width: 1200px;
    }

    .tunisia-program-feature-container{
        max-width: 320px;
    }
}

@media screen and (min-width: 1920px){
    .tunisia-features-section-container{
        max-width: 1200px;
    }

    .tunisia-features-section-subtitle-description{
        width: 60%;
    }
}


/* Styling feature items */
.tunisia-program-feature-container{
    display: flex;
    height:100%;
    flex-direction: column;
    gap:0.5rem;
}
.tunisia-program-feature-item-title{
    font-size: 1.25rem;
    font-weight: 700;
    letter-spacing: -0.05em;
    line-height: 1.75rem;
    text-align: start;
    min-height:1.75rem;
}



@media (min-width:1280px){
    .tunisia-program-feature-container{
        display: flex;
        height:100%;
        flex-direction: column;
        /* justify-content: space-between; */
        align-items: start;
        gap:0.5rem;
    }

    .tunisia-program-feature-item-title{
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.75rem;
        /* margin-bottom: auto; */
    }

    .tunisia-program-feature-item-description{
        font-size: 1rem;
        line-height: 1.5rem;
        /* margin: auto 0; */
    }
}

@media (min-width:1300px){
    .tunisia-features-section-title{
        font-size: 2.75rem;
        line-height: 120%;
    }
}

@media (min-width: 1500px){
    .tunisia-features-section-feature-cards-container{
        grid-template-columns: repeat(3, 1fr);
    }

    

}

@media (min-width: 1920px){
    .tunisia-features-section-subtitle-description{
        max-width:65%;
    }

    .tunisia-features-section-title{
        font-size: 3.75rem;
    }
}
