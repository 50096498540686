.accordion-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-wrap: wrap;
    /* border: orange solid; */
  }
  
  .accordion-container-header {
    color: rgba(0, 0, 0, 0.5);
    /* width: 50%; */
    display: flex;
    align-items: center;
    gap: 1vw;
    border-top: black solid 1px;
    padding: 8px 0;
    cursor: pointer;
    transition: all 0.3s ease;
    /* border: red solid; */
  }
  .accordion-container-header.selected{
    border-radius:4px;
  }


   .accordion-container-header:hover {
    border-top: var(--secondary-color-pakistan-green) solid 1px;
  } 
  .accordion-container-header:hover {
    /* color:white; */
    border-top: #25aabe solid 1px;
  } 
  .selected {
    /* color:white !important; */
    background-color: #e6e9ef;
    color: #25aabe;
    /* background-color: #73463d; */
    /* background-color: var(--secondary-color-bard-red);
    --select-accordion-tab-bg-color: color-mix(in srgb, #6f483f 80%, var(--primary-color-safety-orange) 20%);
    background-color: var(--select-accordion-tab-bg-color ); */
  }
  .selected:hover {
    /* color:white; */
    color: #25aabe;
  }
  
  .accordion-center {
    width: 90%;
    text-align: center;
  }
  
  @keyframes rotation-down {
    from {
      transform: rotate(90deg);
    }
    to {
      transform: rotate(-90deg);
    }
  }
  
  .rotate-down {
    transform: rotate(90deg);
    animation: rotation-up 0.3s ease-in-out;
  }
  
  @keyframes rotation-up {
    from {
      transform: rotate(-90deg);
    }
    to {
      transform: rotate(90deg);
    }
  }
  .rotate-up {
    transform: rotate(-90deg);
    animation: rotation-down 0.3s ease-in-out;
  }
  
  .accordion-container-body {
    padding: 0;
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.3s;
    opacity: 0;
    /* border: orange solid; */
  }
  .accordion-container-body[expanded="hide"] *{
    padding: unset;
  } 
  
  .accordion-container-body * {
    overflow: hidden;
  }
  
  .accordion-container-body[expanded="visible"] {
    grid-template-rows: 1fr;
    opacity: 1;
  }
  