.card-container {
    width: 100%;
    min-width: 206px;
    display: flex;
    flex-direction: column;
    gap: 1vh;
    height: fit-content;
    align-items: center;
    justify-content: center;

    /* border: orange solid; */
  }

  .card-container-title{
    text-align: center;
    min-width:fit-content;
  }
  
  @media (max-width: 768px) {
    .card-container {
      align-items: center;
    }
  
    .card-container-description {
      text-align: center;
    }
  }
  
