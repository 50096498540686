.video-section-wrapper {
  /* margin-top: 3vh; */
  isolation: isolate;
  position: relative;
  /* background-color: transparent; */
  width: 100%;
  /* margin: 12vh 0; */
  gap: 2vh;
  /* border: aqua solid; */
}
/*@media (min-width: 1440px) {
  .react-parallax-bgimage {
    top: -108px;
  }
}*/

.video-section-container {
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 55vh;
  min-height: 400px;
  gap: 1vh;
  padding: 8px;
  /* border: greenyellow solid; */
}

@keyframes video-apparition {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
  }
}

.video-section-container.visible * {
  animation: video-apparition 0.5s ease-in-out;
}

.video-section-description {
  text-align: center;
  width: clamp(100px, 80%, 600px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: green solid; */
}

.video-icon-player {
  height: 30px;
  aspect-ratio: 1/1;
}

.video-section-title {
  text-align: center;
}

.video-section-button {
  margin: 0;
  padding: 0;
  background-color: transparent;
  transition: 5s;
  cursor: pointer;
}

.video-section-illustration {
  width: auto;
  /* border: red solid; */
}

.video-section-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  /* border: purple solid; */
}

.video-section-player {
  padding: 0;
  width: 50vw;
  min-width: 350px;
  aspect-ratio: 16/9;
  overflow: visible;
  background-color: transparent;
  border-width: 0;
  border-radius: 50px;
  box-shadow: black;
  /* border: orange solid; */
}

.video-section-player::backdrop {
  background-color: rgba(0, 0, 0, 0.4);
}

.video-section-player-content {
  border-radius: 2%;
}

.video-section-player-button {
  position: absolute;
  display: grid;
  align-content: center;
  top: -15px;
  right: -15px;
  z-index: 1;
  border-radius: 50%;
  background-color: black;
  color: white;
  width: 30px;
  aspect-ratio: 1/1;
  font-size: 100%;
}

.video-section-player-button:hover {
  cursor: pointer;
}

@media (max-width: 1000px) {
  .video-section-wrapper > .strie {
    display: none;
  }
}
