
#adulteV2-section-top-left-strie-1{
    top: 4% !important;
    left: -10% !important;
    transform: scale(2.5) rotate(-50deg) !important;
}

#adulteV2-section-top-left-strie-2{
    top: 16% !important;
    left: -12% !important;
    transform: scale(1.5) rotate(-50deg) !important;
}


@media screen and (min-width: 460px){
    #adulteV2-section-top-left-strie-1{
        top: 12% !important;
        left: -13% !important;
        transform: scale(6) rotate(-50deg) !important;
    }

    #adulteV2-section-top-left-strie-2{
        top: 34% !important;
        left: -13% !important;
        transform: scale(3) rotate(-50deg) !important;
    }
}

@media screen and (min-width: 550px) {
    #adulteV2-section-top-left-strie-1{
        top: 13% !important;
        left: -10% !important;
    }
    
    #adulteV2-section-top-left-strie-2{
        top: 75% !important;
        left: -10% !important;
    }
}

@media screen and (min-width: 820px) {
    #adulteV2-section-top-left-strie-1{
        top: 25% !important;
        left: -10% !important;
    }
    
    #adulteV2-section-top-left-strie-2{
        top: 55% !important;
        left: -8% !important;
    }
}

@media screen and (min-width: 920px) {
    #adulteV2-section-top-left-strie-1{
        top: 30% !important;
        left: -10% !important;
    }
    
    #adulteV2-section-top-left-strie-2{
        top: 55% !important;
        left: -8% !important;
    }
}

@media screen and (min-width: 1024px) {
    #adulteV2-section-top-left-strie-1{
        top: 30% !important;
        left: -10% !important;
        transform: scale(4.5) rotate(-50deg) !important;
    }
    
    #adulteV2-section-top-left-strie-2{
        top: 58% !important;
        left: -8% !important;
        transform: scale(2) rotate(-50deg) !important;
    }
}

@media screen and (min-width: 1200px) {
    #adulteV2-section-top-left-strie-1{
        top: 35% !important;
        left: -10% !important;
        transform: scale(5.5) rotate(-50deg) !important;
    }
    
    #adulteV2-section-top-left-strie-2{
        top: 65% !important;
        left: -8% !important;
        transform: scale(2.5) rotate(-50deg) !important;
    }
}

@media screen and (min-width: 1440px) {
    #adulteV2-section-top-left-strie-1{
        top: 35% !important;
        left: -10% !important;
        transform: scale(6) rotate(-50deg) !important;
    }
    
    #adulteV2-section-top-left-strie-2{
        top: 62% !important;
        left: -8% !important;
        transform: scale(3) rotate(-50deg) !important;
    }
}


@media screen and (min-width: 1550px) {
    #adulteV2-section-top-left-strie-1{
        top: 30% !important;
        left: -10% !important;
    }
    
    #adulteV2-section-top-left-strie-2{
        top: 60% !important;
        left: -10% !important;
    }
}


@media screen and (min-width: 1650px) {
    #adulteV2-section-top-left-strie-1{
        top: 35% !important;
        left: -12% !important;
    }
    
    #adulteV2-section-top-left-strie-2{
        top: 68% !important;
        left: -12% !important;
    }
}


@media screen and (min-width: 1750px) {
    #adulteV2-section-top-left-strie-1{
        top: 35% !important;
        left: -15% !important;
    }
    
    #adulteV2-section-top-left-strie-2{
        top: 68% !important;
        left: -15% !important;
    }
}

@media screen and (min-width: 1810px) {
    #adulteV2-section-top-left-strie-1{
        top: 35% !important;
        left: -17% !important;
    }
    
    #adulteV2-section-top-left-strie-2{
        top: 70% !important;
        left: -17% !important;
    }
}



@media screen and (min-width: 1920px) {
    #adulteV2-section-top-left-strie-1{
        top: 35% !important;
        left: -23% !important;
    }
    
    #adulteV2-section-top-left-strie-2{
        top: 68% !important;
        left: -23% !important;
    }
}


@media screen and (min-width: 2000px) {
    #adulteV2-section-top-left-strie-1{
        top: 35% !important;
        left: -25% !important;
        /* left: -20vw !important; */
        transform: scale(8) rotate(-50deg) !important;
    }
    
    #adulteV2-section-top-left-strie-2{
        top: 85% !important;
        left: -28% !important;
        /* left: -20vw !important; */
        transform: scale(4) rotate(-50deg) !important;
    }
}


@media screen and (min-width: 2200px) {
    #adulteV2-section-top-left-strie-1{
        top: 40% !important;
        left: -25% !important;
        /* left: -20vw !important; */
        transform: scale(8.5) rotate(-50deg) !important;
    }
    
    #adulteV2-section-top-left-strie-2{
        top: 95% !important;
        left: -30% !important;
        /* left: -20vw !important; */
        transform: scale(5) rotate(-50deg) !important;
    }
}