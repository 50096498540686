.form-container {
    color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 4vh;
    overflow: visible;
  }
  
  .notification {
    color:#00213b;
    display: flex;
    bottom: 16px;
    position: absolute;
    border-radius: 8px;
    padding: 8px 16px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease;
    animation:  notification-apparition 0.3s ease;
  }
  
  @keyframes notification-apparition {
    from {
      transform: translateX(-8px);
    }
  }
  
  .userbox {
    overflow: visible;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
  }
  .userbox input,
  .userbox select {
    width: 80%;
    height: 30px;
    border: none;
    background: transparent;
    outline: none;
    border-bottom: rgba(0, 0, 0, 0.5) solid 1px;
  }
  .phone-number {
    width: 80%;
    height: 30px;
    border: none;
    background: transparent;
    outline: none;
  }
  
  .userbox textarea {
    padding-top: 30px;
    height: 140px;
    width: 80%;
    border: none;
    background: transparent;
    outline: none;
    resize: none;
    border: rgba(0, 0, 0, 0.5) solid 1px;
  }

  #subject {
    cursor: pointer;
  }

  .animate-label {
    position: absolute;
    top: 0;
    pointer-events: none;
    transition: 0.5s;
  }
  
  .userbox input:focus ~ .animate-label,
  .userbox textarea:focus ~ .animate-label,
  .PhoneInput--focus ~ .animate-label {
    transform: translateY(-20px);
    /* color: #17a8e3; */
    color: var(--secondary-color-bard-red);
  }
  .fixed-label {
    position: absolute;
    top: -20px;
    /* color: #005273; */
    color: var(--secondary-color-pakistan-green);
    transition: none;
  }
  
  .userbox .length {
    position: absolute;
    right: 65px;
    bottom: -25px;
  }
  
  .btn-contact {
    font-weight: 700;
    --contact-form-btn-bg-color: #005238;
    /* color: white;
    background: linear-gradient(
      90deg,
      rgba(243, 93, 171, 1) 10%,
      rgba(253, 204, 17, 1) 90%
    ); */
    /* color:var(--primary-color-harvest-gold); */
    /* background-color: var(--secondary-color-pakistan-green); */
    color: white; 
    background-color: #005238;
    background: linear-gradient(
      to right,
      #6f483f,
      var(--primary-color-safety-orange)
    );
    opacity: 1;
    padding: 10px 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease-in-out;
    margin:0.8rem 0;
  }
  
  .btn-contact:hover {
    opacity: 0.8;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    .phone-label {
      right: 75px;
    }
  }
  