.contact-container {
    width: 100%;
    max-width: 600px;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5vh;
    border-radius: 15px;
    color: var(--secondary-color-pakistan-green);
    background-color: white;
    opacity:0.80;
  }

  .contact-container h2 {
    font-weight: 600;
    line-height: 3rem;
    margin-top: 1rem;
  }