/* ANIMATIONS */
.tunisia-trainings-section--container #training-card-1{
    opacity: 0;
    transform: translateX(-100px);
    transition: all 0.5s ease-in-out;
}

.tunisia-trainings-section--container.visible #training-card-1.visible{
    opacity: 1;
    transform: translateX(0px) translateY(0);
    transition: all 0.5s ease-in-out;
}

.tunisia-trainings-section--container #training-card-2{
    opacity: 0;
    transform: scale(0.6);
    transition: all 0.5s ease-in-out;
}

.tunisia-trainings-section--container.visible #training-card-2.visible{
    opacity: 1;
    transform: scale(1);
    transition: all 0.5s ease-in-out;
}

.tunisia-trainings-section--container #training-card-3{
    opacity: 0;
    transform: translateX(100px);
    transition: all 0.5s ease-in-out;
}

.tunisia-trainings-section--container.visible #training-card-3.visible{
    opacity: 1;
    transform: translateX(0px) translateY(0);
    transition: all 0.5s ease-in-out;
}

.tunisia-trainings-section--container #training-card-4{
    opacity: 0;
    transform: translateX(-100px);
    transition: all 0.5s ease-in-out;
    /* transition-delay: 1s; */
}

.tunisia-trainings-section--container.visible #training-card-4.visible{
    opacity: 1;
    transform: translateX(0px) translateY(0);
    transition: all 0.5s ease-in-out;
}

.tunisia-trainings-section--container #training-card-5{
    opacity: 0;
    transform: scale(0.6);
    transition: all 0.5s ease-in-out;
    /* transition-delay: 1s; */
}

.tunisia-trainings-section--container.visible #training-card-5.visible{
    opacity: 1;
    transform: scale(1);
    transition: all 0.5s ease-in-out;
}

.tunisia-trainings-section--container #training-card-6{
    opacity: 0;
    transform: translateX(100px);
    transition: all 0.5s ease-in-out;
    /* transition-delay: 1s; */
}

.tunisia-trainings-section--container.visible #training-card-6.visible{
    opacity: 1;
    transform: translateX(0px) translateY(0);
    transition: all 0.5s ease-in-out;
}


.tunisia-trainings-section--container.visible #training-card-1.visible:hover{
    transform: translateY(-20px);
    transition: transform 0.3s ease-in-out;
}

.tunisia-trainings-section--container.visible #training-card-2.visible:hover{
    transform: translateY(-20px);
    transition: transform 0.3s ease-in-out;
}


.tunisia-trainings-section--container.visible #training-card-3.visible:hover{
    transform: translateY(-20px);
    transition: transform 0.3s ease-in-out;
}

.tunisia-trainings-section--container.visible #training-card-4.visible:hover{
    transform: translateY(-20px);
    transition: transform 0.3s ease-in-out;
}

.tunisia-trainings-section--container.visible #training-card-5.visible:hover{
    transform: translateY(-20px);
    transition: transform 0.3s ease-in-out;
}


.tunisia-trainings-section--container.visible #training-card-6.visible:hover{
    transform: translateY(-20px);
    transition: transform 0.3s ease-in-out;
}


/* Adjusting animations for screens on which disposition of cards is 3 rows of 2 cards by row */
@media (min-width:600px) and (max-width:1600px){
    .tunisia-trainings-section--container #training-card-1{
        opacity: 0;
        transform: translateX(-100px);
        transition: all 0.5s ease-in-out;
    }
    
    .tunisia-trainings-section--container.visible #training-card-1.visible{
        opacity: 1;
        transform: translateX(0px) translateY(0);
        transition: all 0.5s ease-in-out;
    }
    
    .tunisia-trainings-section--container #training-card-2{
        opacity: 0;
        transform: translateX(100px);
        transition: all 0.5s ease-in-out;
    }
    
    .tunisia-trainings-section--container.visible #training-card-2.visible{
        opacity: 1;
        transform: translateX(0px) translateY(0);
        transition: all 0.5s ease-in-out;
    }
    
    .tunisia-trainings-section--container #training-card-3{
        opacity: 0;
        transform: translateX(-100px);
        transition: all 0.5s ease-in-out;
    }
    
    .tunisia-trainings-section--container.visible #training-card-3.visible{
        opacity: 1;
        transform: translateX(0px) translateY(0);
        transition: all 0.5s ease-in-out;
    }
    
    .tunisia-trainings-section--container #training-card-4{
        opacity: 0;
        transform: translateX(100px);
        transition: all 0.5s ease-in-out;
        }
    
    .tunisia-trainings-section--container.visible #training-card-4.visible{
        opacity: 1;
        transform: translateX(0px) translateY(0);
        transition: all 0.5s ease-in-out;
    }
    
    .tunisia-trainings-section--container #training-card-5{
        opacity: 0;
        transform: translateX(-100px);
        transition: all 0.5s ease-in-out;
    }
    
    .tunisia-trainings-section--container.visible #training-card-5.visible{
        opacity: 1;
        transform: translateX(0px) translateY(0);
        transition: all 0.5s ease-in-out;
    }
    
    .tunisia-trainings-section--container #training-card-6{
        opacity: 0;
        transform: translateX(100px);
        transition: all 0.5s ease-in-out;
    }
    
    .tunisia-trainings-section--container.visible #training-card-6.visible{
        opacity: 1;
        transform: translateX(0px) translateY(0);
        transition: all 0.5s ease-in-out;
    }
}