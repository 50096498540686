@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import "~@fortawesome/fontawesome-free/css/all.css";

/*------------global-----------*/
.container-senegal {
  font-family: "Roboto", sans-serif;
  max-width: 84%;
  margin: auto;
}
.flexSB-senegal {
  display: flex;
  justify-content: space-between;
}
.icon-senegal {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  color: #03213b;
  transition: 0.5s;
}
.icon-senegal:hover {
  cursor: pointer;
  background-color: #142e46;
  color: #fff;
  transform: scale(1.2) rotate(10deg);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
/* a {
  text-decoration: none;
}
li {
  list-style-type: none;
} */
.row-senegal {
  width: 50%;
}
.button-se {
  padding: 17px 30px;
  background: linear-gradient(to right, #6f483f, var(--primary-color-safety-orange));
  font-weight: 600;
  border: none;
  margin: 30px 10px 0 0;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 24px 36px -11px rgb(0 0 0 / 9%);
  position: relative;
  overflow: hidden;
  transition: 0.5s;
  color: #fff;
}
/* button i {
  margin-left: 20px;
} */
.button-se::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
  transition: all 0.5s;
  z-index: 0;
}
.button-se:hover::before {
  left: 100%;
}
.button-se:hover {
  box-shadow: none;
  background: linear-gradient(
    to right,
    #777777,
    var(--primary-color-safety-orange)
  );
  transform: translateY(-5px) scale(1.05);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}
.primary-btn-senegal {
  background-color: #6F1A07;
  color: #fff;
}

/*------------global-----------*/
/* #heading {
  text-align: center;
  padding: 40px 0;
}
#heading h3 {
  font-weight: 600;
  letter-spacing: 1px;
  color: #1eb2a6;
  text-transform: uppercase;
}
#heading h1 {
  font-size: 45px;
  margin: 20px 0;
  text-transform: capitalize;
} */
/* p {
  line-height: 30px;
  font-size: 18px;
} */

.back-senegal {
  /* background-image: url("../../media/images/contact-img.svg"); */
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 30%;
  padding-right: 50px;
  color: #fff;
  text-align: center;
}
.back-senegal h1 {
  font-size: 100px;
  font-weight: 400;
}
.back-senegal h2 {
  font-weight: 500;
  font-size: 17px;
  text-transform: uppercase;
}
.margin-senegal {
  margin-top: 40.3%;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}
.flex {
  display: flex;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
.outline-btn {
  margin: 0;
  box-shadow: none;
  border: 2px solid #6F1A07;
  width: 100%;
  transition: 0.5s;
  border-radius: 8px;
    background: linear-gradient(
    to right,
    #6f483f,
    var(--primary-color-safety-orange)
  );
  
}
.outline-btn:hover {
  background-color: #6F1A07;
  background: linear-gradient(
    to right,
    #a09e9e,
    var(--primary-color-safety-orange)
  );
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 50px;
}
.padding-senegal {
  padding: 80px 0;
}
.shadow-senegal {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
}
@media screen and (max-width: 768px) {
  .grid2,
  .grid3,
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .back-senegal {
    background-position: center;
    padding-top: 50%;
    height: 80vh;
  }
}