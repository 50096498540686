.adulte-section-container {
  width: 85%;
  min-width: min-content;
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  gap: 2vw;
  /* border: greenyellow solid; */
}

.adulte-section-description {
  min-width: 300px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2vh;
}

.adulte-section.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;
  transition: 2s;
}
.adulte-section.header:hover {
  transform: scale(1);
  color: #dd5c29;
  h2 {
    font-size: 50px;
  }
}

.adulte-section-button {
  width: fit-content;
  text-decoration: none;
  position: relative;
  color: #236aae;
  display: flex;
}
.adulte-section-button:hover {
  color: #25aabe;
}

.adulte-section-button:before {
  content: "";
  border-bottom: 1px solid #25aabe;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  transition: 0.3s;
}

.adulte-section-button:hover:before {
  width: 100%;
  padding: 0;
}

.adulte-section-illustration {
  /* min-width: 300px; */
  min-width: 200px;
  max-height: 50vw;
  flex: 1;
  aspect-ratio: 1/1;
  /* border: red solid; */
}

.adulte-section-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  /* border: purple solid; */

  box-shadow: 0px 5px 10px 0px #e9e9e9;
  transform: scale(1);
  opacity: 1;
}
.adulte-section-image:hover {
  transition: 200ms;
  transform: scale(1.03);
  box-shadow: 0px 5px 10px 0px rgb(216 148 67 / 54%);
}

/* ANIMATIONS */
.bounce-in-right {
  animation: bounce-in-right 2s ease;
}
@keyframes bounce-in-right {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(-30px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.bounce-in-left {
  animation: bounce-in-left 2s ease;
}
@keyframes bounce-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1000px);
  }
  60% {
    opacity: 1;
    transform: translateX(30px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.adulte-section-description {
  opacity: 0;
}

.adulte-section-description.visible {
  opacity: 1;
  animation: adulte-section-description-slide-in-right 3s
    cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  cursor: alias;

  ul li:hover {
    color: #ff6600; /* Couleur au survol */
    transform: scale(1.1); /* Agrandissement au survol */
  }
}

.bounce-in-right.visible {
  animation: bounce-in-right 2s ease forwards;
}

@keyframes adulte-section-slide-in-right {
  0% {
    opacity: 0;
    transform: translateX(150px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes adulte-section-description-slide-in-right {
  0% {
    opacity: 0;
    transform: translateX(-50px) translateY(35px);
  }
  100% {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
}

.adulte-section-illustration {
  opacity: 0;
  width: 100%;
}

.adulte-section-illustration.visible {
  opacity: 1;
  /* animation: bounce-in-right 3s ease forwards; */
  animation: adulte-section-slide-in-right 2s cubic-bezier(0.25, 0.1, 0.25, 1)
    forwards;
}
