.trainings-section-wrapper {
  isolation: isolate;
  position: relative;
  /* background-color: transparent; */
  background-color: white;
  min-width: min-content;
  width: 100%;
  /* max-width: 1400px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 6vh 0; */
  padding: 6vh 0 16vh 0;
  gap: 2vh;
}

.trainings-section-title-container {
  margin: 1rem;
}

.trainings-section-title {
  font-weight: bold;
  color: var(--trainings-section-title-color);
}

.training-card-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(85, 2, 2, 0.25); */

  --bard-red-variant: rgba(111, 26, 7, 0.1);
  --harvest-gold-variant: rgba(221, 157, 58, 0.2);
  --pakistan-green-variant: rgba(28, 64, 19, 0.1);
  --safety-orange-variant: rgba(242, 118, 1, 0.2);
  --selective-yellow-variant: rgba(255, 182, 2, 0.1);

  background-color: var(--harvest-gold-variant);
  /* background-color: var(--safety-orange-variant); */
  /* transition: background-color  ease-out 0.7s; */
  border-radius: 15px;
  z-index: 1;
}

.background-container > * {
  position: relative;
  z-index: 2;
}

.training-card-item {
  position: relative;

  --bard-red-variant: rgba(111, 26, 7, 0.1);
  --harvest-gold-variant: rgba(221, 157, 58, 0.1);
  --pakistan-green-variant: rgba(28, 64, 19, 0.1);
  --safety-orange-variant: rgba(242, 118, 1, 0.1);
  --selective-yellow-variant: rgba(255, 182, 2, 0.1);

  /* transition: box-shadow 0.6s ease-in-out, color 0.5s ease-in-out; */
  transition: box-shadow ease-in-out 0.8s, color ease-in-out 0.7s;

  /* box-shadow: 
    inset calc(var(--itemWidthMinus) * 0.3) calc(var(--itemWidthMinus)*1) 0 var(--harvest-gold-variant), 
    inset calc(var(--itemWidth)*0.3) calc(var(--itemWidthMinus)*1) 0 var(--bard-red-variant), 
    inset calc(var(--itemWidthMinus) * 0.3) calc(var(--itemWidth)*1) 0 var(--pakistan-green-variant), 
    inset calc(var(--itemWidth)*0.3) calc(var(--itemWidth)*1) 0 var(--selective-yellow-variant),
    0 0 20px silver; */
  /* 
    box-shadow: 
    inset 0 0 0 rgba(111,26,7,0.15), 
    inset 0 0 0 rgba(111,26,7,0.15), 
    inset 0 0 0 rgba(111,26,7,0.15), 
    inset 0 0 0 rgba(111,26,7,0.15), 
    0 0 20px silver; */

  box-shadow: inset 0 0 0 rgba(111, 26, 7, 0.2),
    inset 0 0 0 rgba(111, 26, 7, 0.2), inset 0 0 0 rgba(111, 26, 7, 0.2),
    inset 0 0 0 rgba(111, 26, 7, 0.2), 0 0 20px silver;

  /* box-shadow: 
    inset 0 0 0 transparent, 
    inset 0 0 0 transparent, 
    inset 0 0 0 transparent, 
    inset 0 0 0 transparent, 
    0 0 20px silver; */

  text-align: center;
  cursor: pointer;
  color: white;
  font-size: calc(var(--itemWidth) / 7);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: var(--item-width);
  aspect-ratio: 6/4;
}

.training-card-item:hover.bg-hover-bard-red-variant-strong {
  --bard-red-variant: rgba(111, 26, 7, 0.35);
  --harvest-gold-variant: rgba(221, 157, 58, 0.1);
  --pakistan-green-variant: rgba(28, 64, 19, 0.1);
  --safety-orange-variant: rgba(242, 118, 1, 0.1);
  --selective-yellow-variant: rgba(255, 182, 2, 0.1);
}

.training-card-item:hover.bg-hover-harvest-gold-variant-strong {
  --harvest-gold-variant: rgba(221, 157, 58, 0.45);
  box-shadow: inset calc(var(--itemWidthMinus) * 0.95)
      calc(var(--itemWidthMinus) * 1) 0 var(--harvest-gold-variant),
    inset calc(var(--itemWidth) * 0.95) calc(var(--itemWidthMinus) * 1) 0
      var(--harvest-gold-variant),
    inset calc(var(--itemWidthMinus) * 0.95) calc(var(--itemWidth) * 1) 0
      var(--harvest-gold-variant),
    inset calc(var(--itemWidth) * 0.95) calc(var(--itemWidth) * 1) 0
      var(--harvest-gold-variant),
    0 0 20px silver;
}

.training-card-item:hover.bg-hover-harvest-gold-variant-light {
  --harvest-gold-variant: rgba(221, 157, 58, 0.25);
  box-shadow: inset calc(var(--itemWidthMinus) * 0.95)
      calc(var(--itemWidthMinus) * 1) 0 var(--harvest-gold-variant),
    inset calc(var(--itemWidth) * 0.95) calc(var(--itemWidthMinus) * 1) 0
      var(--harvest-gold-variant),
    inset calc(var(--itemWidthMinus) * 0.95) calc(var(--itemWidth) * 1) 0
      var(--harvest-gold-variant),
    inset calc(var(--itemWidth) * 0.95) calc(var(--itemWidth) * 1) 0
      var(--harvest-gold-variant),
    0 0 20px silver;
}

.training-card-item:hover.bg-hover-safety-orange-variant-light {
  --safety-orange-variant: rgba(242, 118, 1, 0.15);
  box-shadow: inset calc(var(--itemWidthMinus) * 0.95)
      calc(var(--itemWidthMinus) * 1) 0 var(--safety-orange-variant),
    inset calc(var(--itemWidth) * 0.95) calc(var(--itemWidthMinus) * 1) 0
      var(--safety-orange-variant),
    inset calc(var(--itemWidthMinus) * 0.95) calc(var(--itemWidth) * 1) 0
      var(--safety-orange-variant),
    inset calc(var(--itemWidth) * 0.95) calc(var(--itemWidth) * 1) 0
      var(--safety-orange-variant),
    0 0 20px silver;
}

.training-card-item:hover.bg-hover-safety-orange-variant-strong {
  --safety-orange-variant: rgba(242, 118, 1, 0.35);
  box-shadow: inset calc(var(--itemWidthMinus) * 0.95)
      calc(var(--itemWidthMinus) * 1) 0 var(--safety-orange-variant),
    inset calc(var(--itemWidth) * 0.95) calc(var(--itemWidthMinus) * 1) 0
      var(--safety-orange-variant),
    inset calc(var(--itemWidthMinus) * 0.95) calc(var(--itemWidth) * 1) 0
      var(--safety-orange-variant),
    inset calc(var(--itemWidth) * 0.95) calc(var(--itemWidth) * 1) 0
      var(--safety-orange-variant),
    0 0 20px silver;
}

.training-card-item:hover::before {
  background-color: transparent;
}

.training-card-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
  color: white;
}

.training-card-title {
  display: inline-block;
  font-weight: 600;
  margin: 0.2rem 0;
  font-size: 24px;
}

.training-card-subtitle {
  display: inline-block;
  font-weight: 500;
  margin: 0.2rem 0;
}

.training-card-subtitle:before {
  content: "";
  border-bottom: 2px solid white;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  transition: 0.3s;
}

.training-card-item:hover .training-card-subtitle:before {
  width: 80%;
  padding: 0;
}

.trainings-section-cards-container {
  --itemWidth: min(500px, 90vw);
  --itemWidthMinus: calc(-1 * var(--itemWidth));
  height: max-content;
  width: 85%;
  max-width: 1200px;
  display: grid;
  place-content: center;
  grid-template-rows: repeat(2, max-content);
  /* grid-template-columns: repeat(1, calc(3vw + var(--itemWidth))); */
  grid-template-columns: 1fr;
  row-gap: 2vh;
  font-family: "Averia Serif Libre";
}

@media screen and (min-width: 800px) {
  .trainings-section-cards-container {
    grid-template-columns: repeat(3, 329px);
    grid-auto-rows: auto;
    max-width: 1024px;
    gap: 2vh;
    width: 80%;
  }
}

@media screen and (aspect-ratio: 1024/600) {
  .trainings-section-wrapper {
    padding-bottom: 24vh;
  }
}

@media screen and (aspect-ratio: 1280/800) {
  .trainings-section-wrapper {
    padding-bottom: 24vh;
  }
}

.bounce-in-right {
  animation: bounce-in-right 1s ease;
}
@keyframes bounce-in-right {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(-30px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

.bounce-in-left {
  animation: bounce-in-left 1s ease;
}
@keyframes bounce-in-left {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(30px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.hidden {
  opacity: 0;
}
