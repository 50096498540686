.intro-section-container {
  background-color: transparent;
  min-width: min-content;
  width: 90%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6vh 0;
  gap: 2vh;
  /* border: aqua solid; */
}

.transparent-container {
  margin-top: 3vh;
  background-color: white;
  border-radius: 5px;
}

@keyframes intro-apparition {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
  }
}

.intro-section-container.visible *
/* changement d'animation*/
 /*{
    animation: intro-apparition 0.5s ease-in-out;
  }*/ {
  opacity: 1;
  transform: translateY(0) scale(1);

  margin-top: 15px;
}

.intro-section-header {
  min-width: min-content;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  /* border: green solid; */
  /*animation h6*/
  h6 {
    opacity: 0;
    transform: translateY(35px) scale(0.8);
    transition: all 1.2s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
}

.intro-section-title {
  text-align: center;
}

.intro-section-subtext {
  text-align: center;
}

.intro-section-body {
  padding: 20px 0;
  min-width: min-content;
  width: 70%;
  display: flex;
  gap: 1vw;
  /* border: blue solid; */
}

.intro-section-body-separator {
  border: rgba(220, 220, 220, 0.749) solid 1px;
}

.intro-section-footer {
  min-width: min-content;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  /* border: green solid; */
}

/* ANIMATIONS */
@keyframes intro-section-card-slide-in {
  0% {
    opacity: 0;
    transform: translateX(-50px) translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
}

.intro-section-card-animation-wrapper {
  opacity: 0;
}

.intro-section-card-animation-wrapper.visible {
  animation: intro-section-card-slide-in 2s ease forwards;
}

.intro-section-card-delay-1 {
  animation-delay: 0.2s !important;
}

.intro-section-card-delay-2 {
  animation-delay: 0.5s !important;
}

.intro-section-card-delay-3 {
  animation-delay: 0.8s !important;
}

/* RESPONSIVENESS */
@media (max-width: 800px) {
  .intro-section-body {
    flex-direction: column;
    gap: 4vh;
  }

  .intro-section-body-separator {
    display: none;
  }
}
