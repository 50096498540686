

.about-us-section-illustration{
    width:90%;
    flex-basis: 100%;
}

.about-us-section-image{
    width:100%;
    height:100%;
    object-fit: cover;
    border-radius: 15px;
    /* box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.3); */
    /* box-shadow: 0px 0px 1px 0px #00213b; */
}

@media (min-width: 550px){
    .about-us-section-illustration{
        min-width: 60%;
        transform: translateX(10%);
    }
}

@media (min-width: 1024px){
    .about-us-section-illustration{
        min-width: 600px;
    }

    .about-us-context-box{
        max-width: 600px;
        min-width:40%;
    }
}

@media (min-width: 1536px){

    .about-us-section-illustration{
        min-width: 700px;
    }

}

@media (min-width: 1920px){
    .about-us-section-illustration{
        /* min-width: 800px; */
        min-width: 45vw;
        max-width: 1000px !important;
    }
}

