@media screen and (max-width:550px) {
    #trainings-section-top-left-strie-1{
      top: 8% !important;
    }
    #trainings-section-top-left-strie-2{
      top: 11% !important;
      left: -6% !important;
      z-index: -2 !important;
    }

    #trainings-section-bottom-right-strie-1{
      top: 89% !important;
      left: 95% !important; 
      z-index: -2 !important;
    }

    #trainings-section-bottom-right-strie-2{
      top: 92% !important;
      left: 95% !important;
    }
  }

  @media screen and (min-width:550px) and (max-width:700px) {
    #trainings-section-top-left-strie-1{
      top: 7% !important;
      left: -6% !important;
      transform: scale(5) rotate(-50deg) !important;
      z-index: -2;
    }
    #trainings-section-top-left-strie-2{
      top: 12.5% !important;
      left: -6% !important;
    }

    #trainings-section-bottom-right-strie-1{
      top: 89% !important;
      left: 95% !important; 
    }

    #trainings-section-bottom-right-strie-2{
      top: 92% !important;
      left: 100% !important;
      transform: scale(5) rotate(-50deg) !important;
    }
  }

  @media screen and (min-width:700px) and (max-width:768px) {
    #trainings-section-top-left-strie-1{
      top: 7% !important;
      left: -6% !important;
      transform: scale(5) rotate(-50deg) !important;
      z-index: -2;
    }
    #trainings-section-top-left-strie-2{
      top: 10% !important;
      left: -3% !important;
    }

    #trainings-section-bottom-right-strie-1{
      top: 90% !important;
      left: 95% !important; 
    }

    #trainings-section-bottom-right-strie-2{
      top: 92% !important;
      left: 100% !important;
      transform: scale(5) rotate(-50deg) !important;
    }
  }

  @media screen and (min-width:768px){
    #trainings-section-top-left-strie-1{
      top: 6% !important;
      left: -3% !important;
      transform: scale(4.5) rotate(-50deg) !important;
      
  }
  #trainings-section-top-left-strie-2{
      top: 11% !important;
      left: -3% !important;
  }

  #trainings-section-bottom-right-strie-1{
      top: 91% !important;
      left: 94% !important;
  }

  #trainings-section-bottom-right-strie-2{
      top: 94% !important;
      left: 98% !important; 
      transform: scale(4.5) rotate(-50deg) !important;
  }
  }

  @media screen and (min-width:800px){
    #trainings-section-top-left-strie-1{
      top: 20% !important;
      left: -3% !important;
      transform: scale(4.5) rotate(-50deg) !important;
      
  }
  #trainings-section-top-left-strie-2{
      top: 32% !important;
      left: -2% !important;
  }

  #trainings-section-bottom-right-strie-1{
      top: 69% !important;
      left: 95% !important;
  }

  #trainings-section-bottom-right-strie-2{
      top: 78% !important;
      left: 98% !important; 
      transform: scale(4.5) rotate(-50deg) !important;
  }
  }

  @media screen and (min-width:920px){
    #trainings-section-top-left-strie-1{
      top: 20% !important;
      left: -3% !important;
      transform: scale(4.5) rotate(-50deg) !important;
      
  }
  #trainings-section-top-left-strie-2{
      top: 30% !important;
      left: -3% !important;
  }

  #trainings-section-bottom-right-strie-1{
      top: 73% !important;
      left: 95% !important;
  }

  #trainings-section-bottom-right-strie-2{
      top: 79% !important;
      left: 98% !important; 
      transform: scale(4.5) rotate(-50deg) !important;
  }
  }


  @media screen and (min-width:1024px){
    #trainings-section-top-left-strie-1{
        top: 17% !important;
        left: -3% !important;
        transform: scale(4.5) rotate(-50deg) !important;
        
    }
    #trainings-section-top-left-strie-2{
        top: 31% !important;
        left: -3% !important;
    }

    #trainings-section-bottom-right-strie-1{
        top: 66% !important;
        left: 96% !important;
    }

    #trainings-section-bottom-right-strie-2{
        top: 77% !important;
        left: 98% !important; 
        transform: scale(4.5) rotate(-50deg) !important;
    }
  }

  @media screen and (min-width:1440px){
    #trainings-section-top-left-strie-1{
        transform: scale(5.5) rotate(-50deg) !important;
        top: 25% !important;
        left: 1% !important;
        
    }
    #trainings-section-top-left-strie-2{
        transform: scale(2) rotate(-50deg) !important;
        top: 47% !important;
        left: -2% !important;
    }

    #trainings-section-bottom-right-strie-1{
        transform: scale(2) rotate(-50deg) !important;
        top: 59% !important;
        left: 96% !important;

    }

    #trainings-section-bottom-right-strie-2{
        transform: scale(5.5) rotate(-50deg) !important;
        top: 78% !important;
        left: 95% !important; 
    }
  }

  @media screen and (min-width:1650px){
    #trainings-section-top-left-strie-1{
        transform: scale(7) rotate(-50deg) !important;
        top: 28% !important;
        left: 0% !important;
        
    }
    #trainings-section-top-left-strie-2{
        transform: scale(3.5) rotate(-50deg) !important;
        top: 50% !important;
        left: 0% !important;
    }

    #trainings-section-bottom-right-strie-1{
        transform: scale(3.5) rotate(-50deg) !important;
        top: 50% !important;
        left: 95% !important;
    }

    #trainings-section-bottom-right-strie-2{
        transform: scale(7) rotate(-50deg) !important;
        top: 72% !important;
        left: 95% !important; 
    }
  }


  @media screen and (min-width:2200px){
    #trainings-section-top-left-strie-1{
        transform: scale(7) rotate(-50deg) !important;
        top: 28% !important;
        left: 0% !important;
        
    }
    #trainings-section-top-left-strie-2{
        transform: scale(3.5) rotate(-50deg) !important;
        top: 50% !important;
        left: 0% !important;
    }

    #trainings-section-bottom-right-strie-1{
        transform: scale(3.5) rotate(-50deg) !important;
        top: 53% !important;
        left: 98% !important;
    }

    #trainings-section-bottom-right-strie-2{
        transform: scale(7) rotate(-50deg) !important;
        top: 76% !important;
        left: 98% !important; 
    }
  }

  @media screen and (aspect-ratio:1280/800) 
{
    #trainings-section-top-left-strie-1{
        top: 18% !important;
        left: -5% !important;
      }
      #trainings-section-top-left-strie-2{
        top: 28% !important;
        left: -2% !important;
        transform: scale(2) rotate(-50deg) !important;
      }
  
      #trainings-section-bottom-right-strie-1{
        top: 66% !important;
        left: 95% !important;
        transform: scale(2) rotate(-50deg) !important;
      }
  
      #trainings-section-bottom-right-strie-2{
        top: 78% !important;
        left: 97% !important;
      }
}


@media screen and (aspect-ratio:1024/600) 
{
    #trainings-section-top-left-strie-1{
        top: 23% !important;
        left: -8% !important;
      }
      #trainings-section-top-left-strie-2{
        top: 32% !important;
        left: -3% !important;
      }
  
      #trainings-section-bottom-right-strie-1{
        top: 65% !important;
        left: 93% !important;
      }
  
      #trainings-section-bottom-right-strie-2{
        top: 75% !important;
        left: 97% !important;
      }
}

@media screen and ((aspect-ratio:853/1280) or (aspect-ratio: 820/1180) or  (aspect-ratio: 912/1368))
{
    #trainings-section-top-left-strie-1{
        top: 20% !important;
        left: -2% !important;
        transform: scale(5) rotate(-50deg) !important;
      }
      #trainings-section-top-left-strie-2{
        top: 32% !important;
        left: -2% !important;
      }
  
      #trainings-section-bottom-right-strie-1{
        top: 65% !important;
      }
  
      #trainings-section-bottom-right-strie-2{
        top: 78% !important;
        left: 95% !important;
        transform: scale(5) rotate(-50deg) !important;
      }
}




@media  screen and (aspect-ratio: 1024/1366) {
    #trainings-section-top-left-strie-1{
        top: 15% !important;
        left: -2% !important;
        transform: scale(5) rotate(-50deg) !important;
      }
      #trainings-section-top-left-strie-2{
        top: 31% !important;
        left: -3% !important;
      }
  
      #trainings-section-bottom-right-strie-1{
        top: 64% !important;
        left: 93% !important;
      }
  
      #trainings-section-bottom-right-strie-2{
        top: 76% !important;
        left: 95% !important;
        transform: scale(5) rotate(-50deg) !important;
      }
}


@media  screen and (aspect-ratio:540/720) {
    #trainings-section-top-left-strie-1{
        top: 5% !important;
        left: -8% !important;
        transform: scale(5) rotate(-50deg) !important;
      }
      #trainings-section-top-left-strie-2{
        top: 10% !important;
        left: -7% !important;
      }
  
      #trainings-section-bottom-right-strie-1{
        top: 87% !important;
        left: 94% !important;
      }
  
      #trainings-section-bottom-right-strie-2{
        top: 90% !important;
        left: 100% !important;
        transform: scale(5) rotate(-50deg) !important;
      }
}
