/* Styling cards */
.training-card-2-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 2rem 1rem;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease-in-out, transform 0.5s ease-in-out;
}

.training-card-2-container:hover {
  cursor: pointer;
  box-shadow: inset 100vw 0 0 0 #f0f2f8;
  transition: box-shadow 0.3s ease-in-out;
  transform: translateY(-20px);
  transition: transform 0.2s ease-in-out;
}

.training-card-2-title {
  width: 90%;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2;
  font-size: clamp(1.5rem, calc(1.9vw + 0.5rem), 1.75rem);
  color: var(--text-foreground);
}

.training-card-2-description {
  width: 90%;
  font-size: 1rem;
  line-height: 1.6;
  color: var(--text-muted-foreground);
  /* margin-top: auto; */
}

.training-card-2-checklist-item-icon {
  display: block;
}

.training-card-2-checklist-container {
  width: 90%;
  display: flex;
  margin-left: 24px;
  /* justify-content: center; */
}

.training-card-2-checklist {
  /* width:80%; */
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.training-card-2-checklist-item {
  display: flex;
  justify-content: start;
  gap: 16px;
}

.training-card-2-cta-btn-container {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: baseline;
  /* margin-left: 24px; */
  position: relative;
}

.training-card-2-cta-btn {
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  background-color: #00213b;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.training-card-2-cta-btn:hover {
  cursor: pointer;
  background-color: #163b60;
  transition: background-color 0.2s ease-in-out;
}

.scrollable-content h3 {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  background: linear-gradient(
    to right,
    #ccdf74,
    var(--primary-color-safety-orange)
  );

  /* padding: 4px 16px; */
  padding: 10px 16px;
  border-radius: 8px;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .training-card-2-container {
    width: 90%;
  }
}

@media (min-width: 1024px) {
  .training-card-2-container {
    width: 85%;
  }
}

@media (min-width: 1600px) {
  .training-card-2-title {
    line-height: 150%;
  }

  .training-card-2-container {
    gap: 24px;
  }
}
