




/************ Banner Css ************/
.banner-mali {
  margin-top: 0;
  padding: 260px 0 100px 0;
  /* background-image: url('./assets/img/banner-bg.png'); */
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner-mali .tagline-mali {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  margin-left: 50px;
  margin-right: 50px;
  display: inline-block;
}
.banner-mali h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner-mali p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner-mali button {
  color: black;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner-mali button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner-mali button:hover svg {
  margin-left: 25px;
}
.banner-mali img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate-mali > .wrap-mali {
  border-right: 0.08em solid #666;
}

/************ Skills Css ************/

/************ Skills Css ************/
/* h5,h2{
    color: white;
} */

.skill.small-skill-section-mali {
    padding: 0 0 30px 0; /* Reduce padding */
    position: relative;
    font-size: 0.8em; /* Scale down the entire section */
  }
  .skill.small-skill-section-mali .skill-bx-mali {
    padding: 40px 30px; /* Reduce padding inside the box */
    margin-top: -30px; /* Adjust margin to fit reduced size */
  }
  .skill.small-skill-section-mali h2 {
    font-size: 35px; /* Reduce heading size */
  }
  .skill.small-skill-section-mali p {
    font-size: 16px; /* Reduce paragraph font size */
    margin: 10px 0 50px 0; /* Adjust margin */
  }
  .skill.small-skill-section-mali .skill-slider-mali .item-mali img {
    width: 40%; /* Reduce image size */
  }
  

/* h6{
    color:white
} */
.skill-bx-mali{
    color: white;
}
.skill-mali {
  padding: 0 0 50px 0;
  position: relative;
}
.skill-bx-mali {
  background: #151515;
  margin-left: 150px;
  margin-right: 150px;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.skill-mali h2 {
	font-size: 45px;
	font-weight: 700;
}
.skill-mali p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}
.skill-slider-mali {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider-mali .item-mali img {
  width: 50%;
  margin: 0 auto 15px auto;
  
}
.background-image-left-mali {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/************ Projects Css ************/
.project-mali {
  padding: 80px 0;
  position: relative;
  background-color: black;
}
.project-mali h2 {
	font-size: 45px;
	font-weight: 700;
  text-align: center;
}
.project-mali p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project-mali .nav-mali.nav-pills-mali {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project-mali .nav-mali.nav-pills-mali .nav-item-mali {
  width: 33.33333%;
}
.project-mali .nav-mali.nav-pills-mali .nav-link-mali {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project-mali .nav-mali.nav-pills-mali .nav-link-mali::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.project-mali .nav-mali.nav-pills-mali .nav-link-mali.active::before {
    width: 100% !important;
}
.project-mali .nav-mali.nav-pills-mali .nav-link-mali.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link-mali#projects-tabs-tab-first-mali {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link-mali#projects-tabs-tab-second-mali {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link-mali#projects-tabs-tab-third-mali {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx-mali {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx-mali::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx-mali:hover::before {
  height: 100%;
}
.proj-txtx-mali {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx-mali:hover .proj-txtx-mali {
  top: 50%;
  opacity: 1;
}
.proj-txtx-mali h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx-mali span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right-mali {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Projects Css ************/
.contact {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}





  