.algeria-program-overview-section-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    /* padding:12rem 0; */
    padding: 12rem 0 6rem 0;
    background-color: white;
    position:relative;
}

@media (min-width: 2000px) {
    .algeria-program-overview-section-wrapper {
        padding: 16rem 0;
    }
  }
  

.algeria-program-overview-section-container{
    width: 85%;
    display: flex;
    flex-direction: column;
    gap:2rem;
    z-index: 2;
}

.algeria-program-overview-section-content{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1.5rem;
    margin-right: 30px;
}


.section-badge{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 0.35rem 0.75rem;
    background-color: #00213b;
    color: white;
    font-weight: 600;
    background: linear-gradient(
      to right,
      #6f483f,
      var(--primary-color-safety-orange)
    );
}

.algeria-program-overview-section-title{
    font-size: 1.875rem;
    font-weight: 700;
    letter-spacing: -0.05em;
    line-height: 2.25rem;
    text-align: start;
}

.algeria-program-overview-section-button{
    width: 120px;
    border-radius: 8px;
    padding: 0.75rem 1.25rem;
    background-color: #00213b;
    text-align: center;
    color: white;
}

.algeria-program-overview-section-button:hover{
    cursor: pointer;
    background-color: #163b60;
    transition: background-color 0.2s ease-in-out;
}


.algeria-program-overview-checklist-title{
    font-size: 1.875rem;
    font-weight: 700;
    letter-spacing: -0.05em;
    line-height: 2.25rem;
    text-align: start;
}

.algeria-program-overview-section-checklist-container{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1.5rem;
}

.algeria-program-overview-section-checklist{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 0.75rem;
}

.algeria-program-overview-section-checklist-item{
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
}


@media (min-width:550px){
    .algeria-program-overview-section-container{
        width: 80%;
    }

    .algeria-program-overview-section-title{
        font-size: 2.5rem;
        line-height: 120%;
    }

    .algeria-program-overview-checklist-title{
        font-size: 2.25rem;
        line-height: 3rem;
    }

}


@media (min-width: 800px){
    .algeria-program-overview-section-container{
        width:90%;
        display:grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 768px){
    .algeria-program-overview-section-container{
        max-width: 85%;
    }
}

@media screen and (min-width:1024px) {
    .algeria-program-overview-section-container{
        max-width: 85%;
        justify-content: space-between;
    }
    .algeria-program-overview-section-checklist-container{
        width: fit-content;
        /* margin-left: auto; */
    }

    .algeria-program-overview-section-title{
        font-size: 2.25rem;
    }

    .algeria-program-overview-checklist-title{
        font-size: 1.75rem;
    }
}

@media (min-width:1300px){
    .algeria-program-overview-section-container{
        max-width: 75%;
    }

    .algeria-program-overview-section-title{
        font-size: 2.75rem;
        line-height: 120%;
    }

    .algeria-program-overview-checklist-title{
        font-size: 2.25rem;
        line-height: 3rem;
    }
}


@media (min-width:1920px){

    .algeria-program-overview-section-wrapper{
        align-items: center;
    }

    .algeria-program-overview-section-container{
        /* max-width: min(1700px, 75%); */
        max-width: 60%;
    }


    .algeria-program-overview-section-content{
        /* max-width: 600px; */
    }

    .algeria-program-overview-section-title{
        font-size: 3.75rem;
        line-height: 120%;
        /* line-height: 3rem; */
    }

    .algeria-program-overview-checklist-title{
        /* font-size: 2rem;
        line-height: 3rem; */
        font-size: 2.75rem;
        line-height: 120%;
    }

    .algeria-program-overview-section-button{
        width: 150px;
        padding: 1rem 1.5rem;
        font-size: 1.25rem;
    }

    .algeria-program-overview-section-description p{
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

    .algeria-program-overview-section-checklist-container{
        gap: 1rem;
    }
    .algeria-program-overview-section-checklist{
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}


/* ANIMATIONS */
#algeria-program-overview-checklist-item-1{
    opacity: 0;
    transform: translateX(50px);
    transition: all 0.9s cubic-bezier(0.25, 0.1, 0.25, 1);
}

#algeria-program-overview-checklist-item-1.visible{
    opacity: 1;
    transform: translateX(0);
}

#algeria-program-overview-checklist-item-2{
    opacity: 0;
    transform: translateX(50px);
    transition: all 0.9s cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-delay: 0.3s;
}

#algeria-program-overview-checklist-item-2.visible{
    opacity: 1;
    transform: translateX(0);
}

#algeria-program-overview-checklist-item-3{
    opacity: 0;
    transform: translateX(50px);
    transition: all 0.9s cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-delay: 0.6s;
}

#algeria-program-overview-checklist-item-3.visible{
    opacity: 1;
    transform: translateX(0);
}

#algeria-program-overview-checklist-item-4{
    opacity: 0;
    transform: translateX(50px);
    transition: all 0.9s cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-delay: 0.9s;
}

#algeria-program-overview-checklist-item-4.visible{
    opacity: 1;
    transform: translateX(0);
}