.coursesCard-senegal {
  padding: 0;
  margin: 0;
  padding: 50px 0;
  margin-left: 50px;
  margin-right: 50px;
  box-sizing: border-box;
}

/* * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
} */
/* body {
  font-family: "Roboto", sans-serif;
  background-color: #f8f8f8;
} */
.coursesCard-senegal .items-senegal {
  background-color: #f3f3f3;
  padding: 30px;
  text-align: center;
}
.coursesCard-senegal .img-senegal {
  width: 50px;
  height: 60px;
  border-radius: 50%;
  background-color: #11423e;
  padding: 15px;
  margin-bottom: 25px;
}
.coursesCard-senegal img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.coursesCard-senegal .text-senegal {
  margin-left: 15px;
  text-align: left;
}
.coursesCard-senegal .text-senegal h1 {
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
}
.coursesCard-senegal .rate-senegal {
  margin: 20px 0;
  color: #03213b;
}
.coursesCard-senegal .rate-senegal i {
  font-size: 13px;
  margin-right: 5px;
}
.coursesCard-senegal .details-senegal .dimg-senegal img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}
.coursesCard-senegal .details-senegal .box-senegal {
  display: flex;
  align-items: center;
  color: grey;
}
.coursesCard-senegal .details-senegal span {
   background: linear-gradient(
    to right,
    #6f483f,
    var(--primary-color-safety-orange)
  );
  font-weight: 500;
  font-size: 14px;
}
.coursesCard-senegal .price-senegal {
  margin: 30px 0;
  background-color: #f8f8f8;
  padding: 10px;
}
.coursesCard-senegal h3 {
  font-weight: 500;
  background: linear-gradient(
    to right,
    #6f483f,
    var(--primary-color-safety-orange)
  );
}
/*-------------online---------*/
.online-senegal {
  text-align: center;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 100px;
}

.online-senegal .box-senegal {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
  padding: 20px 20px;
  padding-left: 50px;
  padding-right: 50px;
  transition: 0.5s;
}
.online-senegal .img-senegal {
  width: 100px;
  height: 100px;
  margin: auto;
  position: relative;
}
.online-senegal img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.online-senegal h1 {
  font-weight: 500;
  font-size: 20px;
  margin: 20px 0;
  line-height: 30px;
}
.online-senegal span {
  background-color: #f8f8f8;
  padding: 5px 2px;
  font-weight: 500;
  font-size: 15px;
  color: #03213b;
  border-radius: 5px;
}
.online-senegal .box-senegal .show-senegal {
  opacity: 0;
}
.online-senegal .box-senegal:hover {
  background: linear-gradient(
    to right,
    #6f483f,
    var(--primary-color-safety-orange)
  );
  border-radius: 5px;
  cursor: pointer;
}
.online-senegal .box-senegal:hover .show-senegal {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
}
.online-senegal .box-senegal:hover h1 {
  color: #fff;
}
.header-senegal {
  text-align: center;
  margin-bottom: 20px;
}

.header-senegal h1 {
  font-size: 2em;
  color: #03213b;
}

/*-------------online---------*/
@media screen and (max-width: 768px) {
}
