.text-primary-harvest-gold {
    color: var(--primary-color-harvest-gold);
}

.text-primary-safety-orange {
    color: var(--primary-color-safety-orange);
}

.text-primary-selective-yellow {
    color: var(--primary-color-selective-yellow);
}

.text-secondary-pakistan-green {
    color: var(--secondary-color-pakistan-green);
}

.text-secondary-bard-red {
    color: var(--secondary-color-bard-red);
}

.bg-primary-harvest-gold {
    background-color: var(--primary-color-harvest-gold);
}

.bg-primary-safety-orange {
    background-color: var(--primary-color-safety-orange);
}

.bg-primary-selective-yellow {
    background-color: var(--primary-color-selective-yellow);
}

.bg-secondary-pakistan-green {
    background-color: var(--primary-color-pakistan-green);
}

.bg-secondary-bard-red {
    background-color: var(--primary-color-bard-red);
}

.text-white {
    color: white;
}



