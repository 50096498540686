.algeria-info-tabs-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  padding: 8rem 0;
  background-color: #f0f2f8;
}

@media (min-width: 1920px) {
  .algeria-info-tabs-section-wrapper {
    padding: 12rem 0;
  }
}

@media (max-width: 1400px) {
  .algeria-info-tabs-section-wrapper .tabs-container {
    display: none;
  }
}

.algeria-info-tabs-section-tabs-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.algeria-info-tabs-section-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

/* Title of the section */
.algeria-info-tabs-section-header-title {
  width: 90%;
  /* font-size: 3rem;
    line-height: 3rem; */
  font-size: 1.875rem;
  line-height: 1.2;
  font-weight: 700;
  letter-spacing: -0.05em;
  text-align: center;
}

@media (min-width: 550px) {
  .algeria-info-tabs-section-header-title {
    max-width: min(70%, 900px);
    font-size: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .algeria-info-tabs-section-header-title {
    font-size: 2.25rem;
    line-height: 120%;
  }
}

@media (min-width: 1300px) {
  .algeria-info-tabs-section-header-title {
    font-size: 2.75rem;
    line-height: 120%;
  }
}

@media (min-width: 1920px) {
  .algeria-info-tabs-section-header-title {
    font-size: 3.75rem;
    line-height: 120%;
    letter-spacing: -0.05em;
  }
}

/* Description */
.algeria-info-tabs-section-header-description {
  font-size: 1rem;
  max-width: 90%;
  line-height: 1.75rem;
  text-align: center;
}

@media (min-width: 550px) {
  .algeria-info-tabs-section-header-description {
    max-width: min(70%, 900px);
  }
}

.tab-content-description-btn-container {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.algeria-info-tabs-section-tabs {
  /* width: 100%; */
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.algeria-info-tabs-section-tabs .tab-content {
  max-width: 1200px;
}

.algeria-info-tabs-section-tabs.tabs-container {
  gap: 24px;
}

.algeria-carousel-info-tabs {
  width: 90%;
}

@media (min-width: 1400px) {
  .algeria-carousel-info-tabs {
    display: none;
  }
}

.algeria-info-tabs-section-tabs .tabs-list {
  max-width: 100%;
}

.algeria-info-tabs-section-tabs .tab-content-image {
  max-width: 750px;
}

.algeria-info-tabs-section-tabs .tabs-list .tab {
  padding: 0.5rem;
}

.algeria-info-tabs-section-tabs .tab-selected {
  border-bottom: 0;
}

.algeria-info-tabs-section-tabs .tab-selected .cta::before {
  width: 100%;
  background-color: var(--hover-fill-color);
}

.algeria-info-tabs-section-tabs .tab-selected .cta {
  color: white;
}

/* ANIMATIONS */

/* Animation for tabs content illustration */
.algeria-info-tabs-section-tabs .info-tabs-section-image {
  opacity: 0;
  transform: scale(0.6);
  transition: all 1s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.algeria-info-tabs-section-tabs .info-tabs-section-image.visible {
  opacity: 1;
  transform: scale(1);
}

/* Animation for tab text content  */
.algeria-info-tabs-section-tabs .tab-content-description {
  opacity: 0;
  transform: translateX(60px) translateY(35px);
  /* transform: scale(0.6); */
  transition: all 1s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.algeria-info-tabs-section-tabs .tab-content-description.visible {
  opacity: 1;
  transform: translateX(0) translateY(0);
  /* transform: scale(1); */
}

/* Animation for header */
.algeria-info-tabs-section-header {
  opacity: 0;
  transform: translateY(35px) scale(0.8);
  transition: all 1.2s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.algeria-info-tabs-section-header.visible {
  opacity: 1;
  transform: translateY(0) scale(1);
}
#steps {
  display: block !important;
}
