@media (max-width:550px){


    #algeria-program-overview-top-right-strie-1{
        top: 5% !important;
        left: 90% !important;
    }

    #algeria-program-overview-top-right-strie-2{
        top: 20% !important;
        left: 90% !important;
    }
}

@media (min-width:550px){
    #algeria-program-overview-top-right-strie-1{
        top: 7% !important;
        left: 93% !important;
    }

    #algeria-program-overview-top-right-strie-2{
        top: 25% !important;
        left: 93% !important;
        transform: scale(8.5) rotate(-50deg) !important;
    }
}

@media (min-width:768px){

    #algeria-program-overview-top-right-strie-1{
        top: 8% !important;
        left: 95% !important;
        transform: scale(4) rotate(-50deg) !important;
    }

    #algeria-program-overview-top-right-strie-2{
        top: 35% !important;
        left: 95% !important;
        transform: scale(10) rotate(-50deg) !important;
    }
}

@media (min-width:920px) and (max-width:1024px){

    #algeria-program-overview-top-right-strie-1{
        top: 7% !important;
        left: 95% !important;
    }

    #algeria-program-overview-top-right-strie-2{
        top: 30% !important;
        left: 96% !important;
        /* transform: scale(10) rotate(-50deg) !important; */
    }
}




@media (min-width: 1024px){
    #algeria-program-overview-top-right-strie-1{
        top: 10% !important;
        left: 95% !important;
        transform: scale(3) rotate(-50deg) !important;
    }

    #algeria-program-overview-top-right-strie-2{
        top: 45% !important;
        left: 95% !important;
        transform: scale(7) rotate(-50deg) !important;
    }
}

@media (min-width: 1440px){
    #algeria-program-overview-top-right-strie-1{
        top: 12% !important;
        left: 95% !important;
        transform: scale(3) rotate(-50deg) !important;
    }

    #algeria-program-overview-top-right-strie-2{
        top: 50% !important;
        left: 95% !important;
        transform: scale(7) rotate(-50deg) !important;
    }
}

@media (min-width: 1920px){
    #algeria-program-overview-top-right-strie-1{
        top: 15% !important;
        left: 96% !important;
        transform: scale(4) rotate(-50deg) !important;
    }

    #algeria-program-overview-top-right-strie-2{
        top: 50% !important;
        left: 96% !important;
        transform: scale(9.5) rotate(-50deg) !important;
    }
}