    /*  ADJUSTING STRIES POSITION FOR DIFFERENT SCREENS */
    
    @media screen and (max-width: 550px){
        #intro-section-top-left-strie-1{
            top: 15% !important;
            left: -15% !important;
          }
          #intro-section-top-left-strie-2{
            top: 60% !important;
            left: -10% !important;
          }
          #intro-section-bottom-right-strie-1{
              top: 85% !important; 
              left: 100% !important;
          }
          #intro-section-bottom-right-strie-2{
              top: 40% !important;
              left: 95% !important;
          }
    }

    @media screen and (min-width: 550px) and (max-width: 768px){

      #intro-section-top-left-strie-1{
        top: 16% !important;
        left: -5% !important;
      }

      #intro-section-top-left-strie-2{
        top: 55% !important;
        left: -7% !important;
      }

      #intro-section-bottom-right-strie-2{
          top: 45% !important;
          left: 98% !important;
      }

      #intro-section-bottom-right-strie-1{
        top: 85% !important; 
        left: 98% !important;
    }
    }

    @media screen and (min-width: 768px) and (max-width: 800px){
        #intro-section-top-left-strie-1{
            top: 25% !important;
            left: -3% !important;
          }
          #intro-section-top-left-strie-2{
            top: 55% !important;
            left: -5% !important;
          }
          #intro-section-bottom-right-strie-1{
              top: 80% !important; 
              left: 95% !important;
          }
          #intro-section-bottom-right-strie-2{
              top: 55% !important;
              left: 98% !important;
          }
    }


    @media  screen and (min-width:768px) and (max-width: 1024px) {
        #intro-section-top-left-strie-1{
            top: 20% !important;
            left: -3% !important;
          }
          #intro-section-top-left-strie-2{
            display: none;
          }
          #intro-section-bottom-right-strie-1{
              top: 80% !important; 
              left: 95% !important;
          }
          #intro-section-bottom-right-strie-2{
              display: none;
          }
    }

    
@media screen and (min-width: 1024px){
    #intro-section-top-left-strie-1{
      top: 25% !important;
      left: -3% !important;
    }
    #intro-section-top-left-strie-2{
      top: 35% !important;
      left: -2% !important;
      transform: scale(2) rotate(-50deg) !important;
      display:none;
    }
    #intro-section-bottom-right-strie-1{
        top: 75% !important;
        left: 95% !important;
    }
    #intro-section-bottom-right-strie-2{
        top: 25% !important;
        left: 95% !important;
        transform: scale(2) rotate(-50deg) !important;
        display:none;
    }
}

@media screen and (min-width: 1200px){
  #intro-section-top-left-strie-1{
    top: 25% !important;
    left: -3% !important;
  }
  #intro-section-bottom-right-strie-1{
      top: 70% !important;
      left: 97% !important;
  }
}


@media screen and (min-width: 1550px){
    #intro-section-top-left-strie-1{
      top: 35% !important;
      left: -5% !important;
      transform: scale(6) rotate(-50deg) !important;
    }
    #intro-section-bottom-right-strie-1{
        top: 65% !important; 
        left: 100% !important;
        transform: scale(6) rotate(-50deg) !important;
    }
}

@media screen and (min-width: 1650px){
    #intro-section-top-left-strie-1{
      top: 40% !important;
      left: -10% !important;
      transform: scale(7) rotate(-50deg) !important;
    }
    #intro-section-bottom-right-strie-1{
        top: 65% !important; 
        left: 105% !important;
        transform: scale(7) rotate(-50deg) !important;
    }

}

@media screen and (min-width: 1900px){
    #intro-section-top-left-strie-1{
      top: 35% !important;
      left: -15% !important;
      transform: scale(9) rotate(-50deg) !important;
    }
    #intro-section-bottom-right-strie-1{
        top: 65% !important;
        left: 108% !important;
        transform: scale(9) rotate(-50deg) !important;
    }
}

@media screen and (aspect-ratio: 1024/600){
  #intro-section-bottom-right-strie-1{
      top: 70% !important;
      left: 98% !important;
  }
}

