.create-startup-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4vh 0;
  width: 100%;
  --create-startup-section-title-color: #00213b;
  --create-startup-section-button-bg-color: #00213b;
  --create-startup-section-button-hover-bg-color: #003762;
  --create-startup-section-button-text-color: white;
  padding: 2vh 0 6vh 0;
  position: relative;
}

.create-startup-image-card {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-decoration: none;
  justify-self: center;
  box-shadow: inset 100vw 0 0 0 #5d513599;
  color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.create-startup-image-card:hover {
  box-shadow: inset 100vw 0 0 0 #2b455a5c;
}

.create-startup-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create-startup-card-container-description {
  position: relative;
  text-decoration: none;
}

.create-startup-card-container-header {
  text-align: center;
}

.create-startup-card-container-description:before {
  content: "";
  border-bottom: 1px solid white;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  transition: 0.3s;
}
.create-startup-image-card:hover
  .create-startup-card-container
  .create-startup-card-container-description:before {
  width: 85%;
  padding: 0;
}

.link-wrapper {
  height: 100%;
  width: 100%;
}

.create-startup-section-container {
  margin: 1rem 0;
  width: 80%;
  gap: 36px;
  height: auto;
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(16, 10px);
  /* justify-content: center; */
  place-content: center;
}

.create-startup-image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

/* POSITION INDIVIDUAL CARDS */
/* Disposition V1 */
#card-1 {
  grid-row: 1/10;
  grid-column: 1/6;
}

#card-2 {
  grid-row: 1/10;
  grid-column: 11/16;
}

#card-3 {
  grid-row: 1/10;
  grid-column: 6/11;
}

#card-4 {
  grid-row: 10/19;
  grid-column: 1/9;
}

#card-5 {
  grid-row: 10/19;
  grid-column: 9/16;
}

.create-startup-section-header-container {
  width: 90%;
  margin: 4rem 0 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.create-startup-section-title {
  font-weight: bold;
  color: var(--create-startup-section-title-color);
  text-align: center;
}

.create-startup-section-header-button {
  margin: 0 auto;
  background-color: var(--create-startup-section-button-bg-color);
  color: var(--create-startup-section-button-text-color);
  font-size: 0.9rem;
  padding: 1rem 2rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* background-color: color-mix(in srgb, #6f483f 70%, var(--primary-color-safety-orange) 30%); */
}

.create-startup-section-header-button:hover {
  /* background-color: #ededed; */
  background-color: var(--create-startup-section-button-hover-bg-color);
  color: var(--create-startup-section-button-text-color);
}

.create-startup-text-content {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow: hidden;
  padding: 1rem;
}

@media (min-width: 550px) {
  .create-startup-section-header-container {
    margin: 3rem 0 1rem 0;
  }

  .create-startup-section-container {
    gap: 3vw;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 1024px) {
  .create-startup-section-wrapper {
    justify-content: center;
  }

  .create-startup-section-container {
    max-width: 1024px;
    gap: 2vw;
  }
}

@media (min-width: 1920px) {
  .create-startup-section-header-container {
    margin: 4rem 0 2rem 0;
    gap: 2rem;
  }

  .create-startup-section-container {
    gap: 64px;
  }
}

@media (max-width: 550px) {
  .create-startup-section-container {
    gap: 1vh;
    width: 95%;
    /* grid-template-rows: repeat(16, 25px); */
    display: flex;
    flex-direction: column;
    height: min-content;
    gap: 8px;
  }

  .create-startup-image-card {
    min-height: 160px;
    aspect-ratio: 16/14;
  }

  .create-startup-section-header-container {
    margin: 2rem 0 1rem 0;
  }

  .create-startup-card-container-title {
    font-size: 2.2rem;
  }

  .create-startup-card-container-description {
    font-size: 1.2rem;
  }
}

@media (min-width: 550px) {
  .create-startup-image-card {
    width: 100%;
  }

  .create-startup-section-container {
    width: 90%;
  }

  .create-startup-card-container-title {
    font-size: 1.4rem !important;
    margin: 0 0.5rem 0 0.5rem;
    text-wrap: wrap;
  }
}

@media (min-width: 768px) {
  .create-startup-section-container {
    grid-template-rows: repeat(16, 15px);
  }

  .create-startup-card-container-title {
    font-size: 1.8rem !important;
  }
}

@media (min-width: 1024px) {
  .create-startup-section-container {
    /* grid-template-rows: repeat(16, 30px); */
    grid-template-rows: repeat(16, 18px);
    grid-template-columns: repeat(16, 30px);
  }

  .create-startup-card-container-title {
    font-size: 2.25rem !important;
    margin: 0 0.5rem 0 0.5rem;
    text-wrap: wrap;
  }
}

@media (min-width: 1440px) {
  .create-startup-section-container {
    max-width: 60%;
    grid-template-rows: repeat(16, 15px);
  }

  .create-startup-card-container-title {
    font-size: 2.2rem !important;
    margin: 0 0.5rem 0 0.5rem;
    text-wrap: wrap;
  }
}

@media screen and (min-width: 1920px) {
  .create-startup-section-container {
    min-width: 1400px;
    grid-template-columns: repeat(16, 1fr);
    grid-template-rows: repeat(16, 10px);
  }

  .create-startup-card-container-title {
    font-size: 3.2rem !important;
    margin: 0 0.5rem 0 0.5rem;
    text-wrap: wrap;
  }
}

/* ANIMATION CLASSES */
