.algeria-intro-section-wrapper{
    width: 100%;
    max-width: 100%;
    /* padding:5rem 0; */
    /* padding:8rem 0; */
    padding:10rem 0;
    display:flex;
    flex-direction:column;
    align-items: center;
    overflow: hidden;
    position: relative;
}

@media (min-width: 2000px) {
    .algeria-intro-section-wrapper {
        padding: 16rem 0;
    }
}


.algeria-intro-section-container{
    width:80%;
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:2rem;
}

.algeria-intro-section-content{
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:2rem;
}

.algeria-intro-section-buttons-container{
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:1rem;

}

.algeria-intro-section-button{
    width:70%;
    border-radius: 8px;
    padding: 1rem 2rem;
    background-color: #00213b;
    text-align: center;
    color: white;
}


@media (max-width:550px){
    .algeria-intro-section-buttons-container .animated-button{
        width: 105px;
    }

    .algeria-intro-section-buttons-container .animated-button{
        display: flex;
        justify-content: center;
    }

}

.algeria-intro-section-button:hover{
    cursor: pointer;
    background-color: #163b60;
    transition: background-color 0.2s ease-in-out;

}

.algeria-intro-section-title{
    font-size: 1.875rem;
    font-weight: 700;
    letter-spacing: -0.05em;
    line-height: 2.25rem;
    text-align: center;
}

.algeria-intro-section-description{
    font-size: 1.25rem;
    color: var(--text-muted-foreground);
    max-width: 37.5rem;
    /* text-align: center; */
    text-align: justify;
    width: 100%;
}

.algeria-intro-section-illustration {
    max-height:400px;
    max-width:100%;
    aspect-ratio: 16/12;
}

.algeria-intro-section-image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}

@media screen and (min-width:550px){

    .algeria-intro-section-container{
        width: 95%;
        gap: 2rem;
    }

    .algeria-intro-section-content{
        align-items: start;
        width: 90%;
        gap:2rem;
    }

    .algeria-intro-section-buttons-container{
        flex-direction: row;
        justify-content: start;
        gap: 2rem;
        /* max-width: 550px; */
    }

    .algeria-intro-section-button{
        width: 160px;
        padding: 1rem 2rem;
    }

    .algeria-intro-section-illustration{
        /* min-width: 90%; */
        width:90%;
        min-height: 400px;
    }
    
    .algeria-intro-section-title{
        font-size: 2.5rem;
        line-height: 120%;
        text-align: start;
        /* text-align: center; */
    }

    .algeria-intro-section-description{
        width: 100%;
        max-width: 90%;
        text-align: start;
    }
}


@media screen and (min-width: 768px){
    .algeria-intro-section-container{
        width:80%;
        max-width:680px;
    }

    .algeria-intro-section-content{
        width: 100%;
        align-self:start;

    }
    .algeria-intro-section-buttons-container{
        width: 100%;
        justify-content: center;
    }

    .algeria-intro-section-description{
        max-width: 100%;
    }

    .algeria-intro-section-illustration{
        width: 100%;
    }


}


@media screen and (min-width: 1024px){
    .algeria-intro-section-title{
        font-size: 2.25rem;
        line-height: 120%;
    }

    .algeria-intro-section-container{
        max-width: 80%;
        gap: 3rem;
    }

    .algeria-intro-section-content{
        gap: 2rem;
        align-items: start;
    }

    .algeria-intro-section-description{
        max-width: 100%;

    }

    .algeria-intro-section-buttons-container{
        width:100%;
        max-width: 100%;
        justify-content: start;
    }

    .algeria-intro-section-button{
        max-width: 100px;
    }

    .algeria-intro-section-illustration{
        max-width: 100%;
        min-height: 500px;
        aspect-ratio: 16/9;
    }
}

@media screen and (min-width:1080px){
    .algeria-intro-section-container{
        width: 85%;
        max-width: 85%;
        flex-direction: row;
        justify-content: space-between;
        align-items: start;
        gap:64px;
    }

    .algeria-intro-section-content{
        max-width: 50%;
        align-items: start;
    }

    .algeria-intro-section-illustration{
        min-width: 50%;
        min-height: 430px;
        aspect-ratio: 1/1;
    }

    .algeria-intro-section-buttons-container{
        gap:1rem;
    }
}

@media screen and (min-width: 1300px){
    .algeria-intro-section-container{
        max-width: 75%;
    }

    .algeria-intro-section-title{
        font-size: 2.75rem;
        line-height: 120%;
    }
}

@media screen and (min-width: 1920px) {

    .algeria-intro-section-container{
        max-width: min(1700px, 75%);
        gap:4rem;
        gap:112px;
    }

    .algeria-intro-section-illustration{
        min-height:500px;
        max-height:800px;
        aspect-ratio: 16/9;
    }


    .algeria-intro-section-title{
        /* font-size: 4.25rem; */
        font-size:3.75rem;
        line-height: 120%;
    }

    .algeria-intro-section-description{
        font-size: 1.5rem;
    }

    .algeria-intro-section-button{
        padding: 1.5rem 2.5rem;
        font-size: large;
    }
}



/* ANIMATIONS */
.algeria-intro-section-illustration {
    opacity: 0.4;
  }
  
.algeria-intro-section-illustration.visible {
    opacity: 1;
    /* animation: bounce-in-right 3s ease forwards; */
    /* animation: algeria-intro-section-illustration-slide-in-right 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards; */
    animation: algeria-intro-section-illustration-zoom-in 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}
  
  
  @keyframes algeria-intro-section-illustration-slide-in-right {
    0% {
      opacity: 0;
      transform: translateX(40px) translateY(35px);
    }
    100% {
      opacity: 1;
      transform: translateX(0) translateY(0);
    }
  }

  @keyframes algeria-intro-section-illustration-zoom-in {
    0% {
      opacity: 0;
      transform: scale(0.6);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

.algeria-intro-section-content {
    opacity: 0.8;
}

.algeria-intro-section-content.visible {
    opacity: 1;
    /* animation: bounce-in-right 3s ease forwards; */
    animation: algeria-intro-section-description-slide-in-left 1s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}

@keyframes algeria-intro-section-description-slide-in-left{
    0% {
        opacity: 0;
        transform: translateX(-50px) translateY(35px);
    }
    100% {
        opacity: 1;
        transform: translateX(0) translateY(0);
    }
}
