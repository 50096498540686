.cta {
  border-radius: 50px;
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap:8px; */
  text-align: center;
  position: relative;
  padding: 19px 22px;
  transition: all 0.2s ease;
  text-wrap: nowrap;
  --hover-fill-color: var(--primary-color-harvest-gold);
}

.cta:hover {
  color: white;
}

.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 28px;
  background: rgb(217 121 47);
  background-color: 00213b;
  width: 56px;
  height: 56px;
  transition: all 0.3s ease;
}

.cta span {
  position: relative;
  font-size: 16px;
  line-height: 18px;
  font-weight: 900;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  vertical-align: middle;
}

.cta svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #000000;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.cta:hover:before {
  width: 100%;
  background-color: var(--hover-fill-color);
}

.cta:hover svg {
  transform: translateX(0);
  stroke: white;
}

.cta:active {
  transform: scale(0.96);
}
