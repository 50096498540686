.testimonal-senegal .items-senegal {
  padding: 30px;
  border-radius: 6px;
  transition: 0.5s;
}

.testimonal-senegal .img-senegal {
  position: relative;
}
.testimonal-senegal img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.container-senegal h2{
display: flex;
justify-content: center;
}
.testimonal-senegal i {
  position: absolute;
  bottom: 0;
  left: 60px;
  background: linear-gradient(
    to right,
    #6f483f,
    var(--primary-color-safety-orange)
  );
  color: #fff;
}
.testimonal-senegal .name-senegal {
  margin: 25px;
}
.testimonal-senegal .name-senegal h2 {
  font-size: 20px;
  margin-bottom: 5px;
}
.testimonal-senegal .name-senegal span {
  color: #03213b;
}
.testimonal-senegal p {
  margin-top: 20px;
  color: grey;
  transition: 0.5s;
}
.testimonal-senegal .items-senegal:hover {
  background: linear-gradient(
    to right,
    #6f483f,
    var(--primary-color-safety-orange)
  );
  color: #fff;
  cursor: pointer;
}
.testimonal-senegal .items-senegal:hover span,
.testimonal .items:hover p {
  color: #fff;
}
.testimonal-senegal .items-senegal:hover i {
  background-color: #fff;
  background: linear-gradient(
    to right,
    #6f483f,
    var(--primary-color-safety-orange)
  );
}
