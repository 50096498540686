

.testimonial-card-1-container{
    border-radius: 0.5rem;
    box-shadow: #f9f9f9;
    padding:1rem;
    display:flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 1rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    background-color: white;
    transition: background-color 0.3s ease-in-out, transform 0.5s ease-in-out;
}

.testimonial-card-1-header{
    display:flex;
    justify-content: start;
    align-items: center;
    gap: 12px;
}

.testimonial-card-1-image{

}

.testimonial-card-1-client-info{}

.testimonial-card-1-body{

}

.testimonial-card-1-client-info-name{
    font-weight: 600;
}

.testimonial-card-1-client-info-company-name{}