.navbar,
.navbar-hidden {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  height: fit-content;
  left: 0px;
  top: 0px;
  /* background: rgba(255, 255, 255, 0.63); */
  background: linear-gradient(to bottom, #d8d1ca, transparent);
  position: fixed;
  transition: 0.5s ease-in-out;
  z-index: 2;
  z-index: 1000;
}

.navbar-hidden,
.eclipse-hidden {
  transform: translateY(-100%);
}

.search-result-box {
  display: grid;
  position: absolute;
  width: 15%;
  height: auto;
  left: 10%;
  grid-template-columns: auto;
  grid-template-rows: repeat(2, auto);
  gap: 1px;
}

.search-box {
  box-sizing: border-box;
  display: flex;
  height: 27px;
  width: 100%;
  border: 1px solid #236aae;
  border-radius: 30px;
}

#in-search {
  border: none;
  background: none;
  font-weight: 400;
  font-size: 1vw;
  line-height: 24px;
  padding-left: 1em;
  width: 80%;

  &::placeholder {
    color: rgba(11, 31, 101, 0.7);
  }

  &:focus {
    outline: none;
  }
}

.search-box span {
  color: #236aae;
  height: 100%;
  padding: 4px;
  padding-left: 10px;
  position: relative;
  z-index: 1;
}

/*box: btn-close-menu + nav-menu-list*/
.nav-menu {
  position: relative;
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;
  z-index: 5;
  /* overflow: hidden; */
  /* border: purple solid; */
}

/*box: links of navbar menu*/
.nav-menu-li {
  width: 100%;
  height: auto;
  padding-right: 0;
  /* border: green solid; */
  display: flex;
}


/*links of navbar menu*/
.nv-li {
  font-size: 1.2rem;
  /* color: var(--secondary-color-bard-red); */
  /* color: #00213b; */
  color: color-mix(
    in srgb,
    var(--primary-color-safety-orange) 20%,
    #6f483f 80%
  );
  padding-right: 0.5em;
  padding-left: 0.5em;
  font-weight: 600;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  text-decoration: none;
}

.nv-li:hover {
  /* color: #497f58; */
  color: #00213b;
}

/* the home link that appears in the burger menu */
.nv-li.welcome {
  visibility: hidden;
}

/*.logo-btn-box :logo + burger button(.nav-btn-menu-burger)*/

.nav-btn-menu-burger {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #00213b;
  display: none;
}

.btn-close-menu {
  visibility: hidden;
}

.logo-btn-box {
  height: min-content;

  /* border: purple solid; */
}
.logo {
  /* border: black solid; */
  height: 100%;
}
.logoImageNavbar {
  width: 128px;
  padding: 8px;
  /* border: orange solid; */
}

.nav-menu-strie-container {
  display: none;
}
.nv-li.cartPage {
  display: flex;
}
/* todo: logo*/
@media screen and (max-width: 760px) {
  .search-box {
    display: none;
  }

  /* box: logo + the btn that display the menu burger*/
  .logo-btn-box {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    /* gap: 77%; */
    width: 90.93%;
    height: 100%;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .logoImage {
    padding: 0px;
  }

  .logo-btn-box .nav-btn-menu-burger {
    display: inline;
    opacity: 1;
    width: 36px;
    height: 36px;
    font-size: 30px;
  }

  /*navbar*/
  .navbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    /* border: black solid; */
    left: 0px;
    top: 0px;
    /* background: rgba(255, 255, 255, 1); */
    gap: 70%;
    /* margin: 0; */
    /* padding: 8px; */
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  /* nav-menu: box -> svg eclipse + btn menu burger + nv-li(the list of menu burger)*/
  .nav-menu {
    display: none;
  }

  /**/
  .nv-li {
    font-size: 24px;
    width: 100%;
    text-align: center;
    color: #00213b;
    position: relative;
  }

  .nv-li:hover {
    color: #358cdd;
  }

  .nav-menu-strie-container {
    display: initial;
  }

  .nav-menu.active {
    display: flex;
    flex-direction: column;
    /* justify-content: start; */
    width: 100%;
    height: 100vh;
    left: 0px;
    top: 0px;
    position: absolute;
    /* background-color: #236aae; */
    background-color: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(3px);
    z-index: 5;
    animation: slide-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    /* animation: slide-left 1s ease-in-out;*/
  }

  .nav-menu.active .btn-close-menu {
    visibility: visible;
    color: #00213b;
    background: none;
    cursor: pointer;
    width: 36px;
    height: 36px;
    font-size: 30px;
    /**/
    /* right: 5%; */
    /* top: 90px; */
    top: 20px;
    right: 20px;
    z-index: 5;
    position: absolute;
    transition: 0.3s ease-in-out;
  }

  .nav-menu.active .btn-close-menu:hover {
    color: #358cdd;
  }

  .nav-menu.active .nav-menu-li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 48px;
    width: 226px;
    height: 316px;
    text-decoration: none;
    /* top: 150px; */
    z-index: 5;
    left: calc(50% - 50%);
    position: relative;
  }

  /*todo : logo*/
}

/* Style the dropdown */

.nv-li.dropdown {
  width: fit-content;
  position: relative;
  z-index: 10;
  display: inline-block;
  /* min-width:80px; */
}

.dropdown-content {
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
  color: #00213b;
  left: -60px;
  right: -60px;
  border-radius: 10px;
  padding: 4px 4px;
}

.dropdown-content a {
  color: #00213b;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
  border-radius: 10px;
  font-size: 1.2rem;
  font-weight: 400;
}

.dropdown-content a:hover {
  /* background-color: #00213b; */
  background: linear-gradient(
    to right,
    #6f483f,
    var(--primary-color-safety-orange)
  );
  color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-btn {
  font-size: 1.2rem;
  font-weight: 600;
}
.dropdown-img{
  width: 40px;
  height: 24px;
  border-radius: 5px;
}

@keyframes slide-left {
  from {
    transform: translateX(100%);
  }

  /* Starts 100% off-screen to the right */
  to {
    transform: translateX(0);
  }

  /* Ends at position 0 (no translation) */
}

@keyframes slide-right {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes slide-down {
  from {
    transform: translateY(-100px);
  }

  to {
    transform: translateY(0);
  }
}
