.home-page-wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    /* height: fit-content; */
    background-color: #f0f2f8;
    /* background-color: #ffefd7; */
    /* --light-brown: color-mix(in srgb,#6f483f 10%, var(--primary-color-safety-orange) 90%);
    background-color: color-mix(in srgb, white 98%, var(--light-brown) 8%); */
    /*  */
    /* --light-brown: color-mix(in srgb,#6f483f 10%, var(--primary-color-safety-orange) 100%);
    background-color: color-mix(in srgb, white 98%, var(--light-brown) 6%); */
  }
  
  .home-sections-wrapper {
    isolation: isolate;
    position: relative;
    background-color: transparent;
    min-width: min-content;
    width: 90%;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6vh 0;
    gap: 2vh;
    /* border: aqua solid; */
  }
  